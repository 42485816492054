import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Row, Col } from "reactstrap";
import DocumentMeta from "react-document-meta";

class PrivacyPolicy extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    document.body.classList = "";
    window.addEventListener("scroll", this.scrollNavigation, true);
    window.scrollTo(0, 0);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.scrollNavigation);
  }

  render() {
    const meta = {
      title: "นโยบายความเป็นส่วนตัว",
      meta: {
        charset: "utf-8",
      },
    };

    return (
      <React.Fragment>
        <DocumentMeta {...meta} />
        <section className="section">
          <div className="container">
            <Row>
              <Col>
                <h6>
                  <Link to="/" className="text-dark">
                    <i className="mdi mdi-arrow-left icons"></i> กลับไปหน้าแรก
                  </Link>
                </h6>
                <h3 className="mb-4">นโยบายความเป็นส่วนตัว</h3>
                <h5>1. บทนำ</h5>
                <p className="text-muted">
                  1.1 ขอต้อนรับสู่ DE LAMUN แพลตฟอร์มที่ดำเนินการโดย DE LAMUN
                  COMPANY LIMITED และบริษัทในกลุ่มและบริษัทในเครือ
                  (เรียกแยกและเรียกรวมกันว่า “DE LAMUN”, “DELAMUN”, “เดอละมุน”,
                  “เดอ ละมุน”, “เรา”, “พวกเรา” หรือ “ของเรา”) DE LAMUN
                  มีหน้าที่รับผิดชอบภายใต้กฎหมายและข้อบังคับความเป็นส่วนตัวที่บังคับใช้
                  (“กฎหมายเกี่ยวกับการคุ้มครองข้อมูลส่วนบุคคล”) อย่างจริงจัง
                  และยึดมั่นต่อการเคารพสิทธิและให้ความสำคัญเกี่ยวกับความเป็นส่วนตัวของผู้ใช้เว็บไซต์
                  และแอปพลิเคชั่นบนมือถือของ DE LAMUN ทั้งหมด (เรียกรวมกันว่า
                  “ไซต์”)
                  (เราเรียกไซต์และบริการที่เรามอบให้ดังที่ระบุไว้ในไซต์ของเราโดยรวมว่า
                  “บริการ”) และ “ผู้ใช้” หมายถึง
                  ผู้ใช้ที่ได้ยืนยันสั่งซื้อของกับเรา
                  หรือมีบัญชีซึ่งได้ลงทะเบียนกับเรา หรือใช้งานไซต์
                  (เรียกแยกและเรียกรวมกันว่า “ผู้ใช้” “คุณ” หรือ “ของคุณ”)
                  เราตระหนักถึงความสำคัญของข้อมูลส่วนบุคคลที่คุณมอบให้แก่เรา
                  และเชื่อว่าเรามีหน้าที่รับผิดชอบในการจัดการ ปกป้อง
                  และดำเนินการข้อมูลส่วนบุคคลของคุณอย่างเหมาะสม
                  นโยบายความเป็นส่วนตัวนี้ (“นโยบายความเป็นส่วนตัว” หรือ
                  “นโยบาย”)
                  ได้รับการออกแบบมาเพื่อช่วยให้คุณเข้าใจเกี่ยวกับวิธีที่เราเก็บรวบรวม
                  ใช้ เปิดเผย
                  และ/หรือดำเนินการข้อมูลส่วนบุคคลที่คุณมอบให้แก่เราและ/หรือข้อมูลของคุณที่เราครอบครองไม่ว่าจะในปัจจุบันหรืออนาคต
                  รวมทั้งยังเป็นข้อมูลประกอบการตัดสินใจก่อนที่คุณจะให้ข้อมูลส่วนตัวใดๆ
                  แก่เรา โปรดอ่านนโยบายความเป็นส่วนตัวนี้โดยละเอียด
                  หากคุณมีข้อสงสัยเกี่ยวกับข้อมูลเหล่านี้หรือข้อปฏิบัติว่าด้วยความเป็นส่วนตัวของเรา
                  โปรดดูส่วนของหัวข้อ “มีคำถาม ข้อกังวล
                  หรือคำร้องเรียนใช่หรือไม่ โปรดติดต่อเรา”
                  ในตอนท้ายของนโยบายความเป็นส่วนตัวนี้
                </p>
                <p className="text-muted">
                  1.2 “ข้อมูลส่วนบุคคล” หมายถึง
                  ข้อมูลเกี่ยวกับบุคคลไม่ว่าจริงหรือเท็จที่สามารถใช้เป็นข้อมูลที่ระบุตัวตนของบุคคลผู้นั้น
                  หรือจากข้อมูลดังกล่าวหรือข้อมูลอื่นๆ
                  ที่องค์กรมีหรืออาจสามารถเข้าถึงได้
                  ตัวอย่างข้อมูลส่วนบุคคลที่พบบ่อยได้แก่ ชื่อ หมายเลขประจำตัว
                  และข้อมูลการติดต่อ
                </p>
                <p className="text-muted">
                  1.3 ในการใช้บริการ การลงทะเบียนบัญชีกับเรา เยี่ยมชมไซต์
                  และ/หรือเข้าถึงบริการ คุณ
                  ได้รับทราบและตกลงว่าคุณยอมรับข้อปฏิบัติ ข้อกำหนด
                  และ/หรือนโยบายที่กำหนดไว้ในนโยบายความเป็นส่วนตัวนี้ และคุณ
                  ยินยอมให้เราเก็บรวบรวม ใช้ เปิดเผย
                  และ/หรือดำเนินการข้อมูลส่วนบุคคลของคุณดังที่ระบุไว้ในนโยบายความเป็นส่วนตัวนี้
                  หากคุณไม่ยินยอมให้เราดำเนินการข้อมูลส่วนบุคคลของคุณดังที่ระบุไว้ในนโยบายความเป็นส่วนตัวนี้
                  โปรดอย่าใช้บริการหรือเข้าถึงไซต์หากเราเปลี่ยนแปลงนโยบายความเป็นส่วนตัวของเรา
                  เราจะโพสต์การเปลี่ยนแปลงดังกล่าวหรือนโยบายความเป็นส่วนตัวที่แก้ไขบนไซต์
                  เราขอสงวนสิทธิ์ในการแก้ไขนโยบายความเป็นส่วนตัวนี้ในทุกเมื่อโดยอาจแจ้งหรือไม่ได้แจ้งให้คุณทราบก็ตาม
                </p>
                <h5>2. DE LAMUN จะเก็บรวบรวมข้อมูลส่วนบุคคลเมื่อใด</h5>
                <p className="text-muted">
                  2.1 เราจะ/อาจเก็บรวบรวมข้อมูลส่วนบุคคลของคุณเมื่อ:
                </p>
                <p className="text-muted">
                  (a) เมื่อคุณได้เข้าถึงและ/หรือใช้บริการหรือไซต์ของเรา
                  หรือสั่งซื้อสินค้า หรือลงทะเบียน
                  และ/หรือสมัครบัญชีผู้ใช้กับเรา
                </p>
                <p className="text-muted">
                  (b) เมื่อคุณส่งแบบฟอร์ม รวมถึงแต่ไม่จำกัดเพียง
                  แบบฟอร์มการสมัครหรือแบบฟอร์มอื่นๆ
                  ที่เกี่ยวข้องกับผลิตภัณฑ์และบริการของเรา
                  ไม่ว่าจะแบบออนไลน์หรือแบบฟอร์มเป็นเอกสาร
                </p>
                <p className="text-muted">
                  (c) เมื่อคุณทำข้อตกลงใดๆ
                  หรือให้เอกสารหรือข้อมูลอื่นใดที่เกี่ยวข้องกับการติดต่อระหว่างคุณกับเรา
                  หรือเมื่อคุณใช้ผลิตภัณฑ์และบริการของเรา
                </p>
                <p className="text-muted">
                  (d) เมื่อคุณติดต่อกับเรา เช่น ผ่านทางโทรศัพท์
                  (ซึ่งจะได้รับการบันทึก) จดหมาย แฟกซ์ การประชุมแบบเห็นหน้ากัน
                  แพลตฟอร์มสื่อทางสังคม และอีเมล
                </p>
                <p className="text-muted">
                  (e) เมื่อคุณใช้บริการทางอิเล็กทรอนิกส์
                  หรือติดต่อกับเราผ่านไซต์หรือใช้บริการ
                  ซึ่งรวมถึงแต่ไม่จำกัดเพียง การใช้ผ่านคุกกี้
                  ซึ่งเราอาจปรับใช้เมื่อคุณใช้บริการหรือเข้าถึงไซต์
                </p>
                <p className="text-muted">
                  (f) เมื่อคุณดำเนินธุรกรรมผ่านบริการของเรา
                </p>
                <p className="text-muted">
                  (g) เมื่อคุณให้ความคิดเห็นหรือคำร้องเรียนแก่เรา
                </p>
                <p className="text-muted">
                  (h) เมื่อคุณลงทะเบียน เข้าทำสัญญา และ/หรือเข้าร่วมในการแข่งขัน
                  และ/หรือกิจกรรมส่งเสริมการขายซึ่งรวมถึงกิจกรรมส่งเสริมการขายร่วมกับหุ้นส่วนและ/หรือบริษัทในกลุ่มของเรา
                </p>
                <p className="text-muted">
                  (i)
                  เมื่อคุณใช้หรือร้องขอจะใช้บริการที่จัดไว้ให้โดยผู้ให้บริการภายนอกของเราบนไซต์
                  เช่น การชำระเงิน บริการด้านโลจิสติกส์ เป็นต้น
                </p>
                <p className="text-muted">
                  (j)
                  เมื่อคุณเข้าเยี่ยมชมหรือใช้เว็บไซต์และแอปพลิเคชั่นของหุ้นส่วนหรือบริษัทในกลุ่มของเรา
                </p>
                <p className="text-muted">
                  (k)
                  เมื่อคุณเข้าเยี่ยมชมหรือใช้เว็บไซต์และแอปพลิเคชั่นของบุคคลภายนอกที่ใช้
                  และ/หรือ เกี่ยวข้องกับบริการของเรา
                </p>
                <p className="text-muted">
                  (l) เมื่อคุณติดตั้งแอปพลิเคชั่นบนคอมพิวเตอร์ โทรศัพท์
                  หรืออุปกรณ์อื่น (“อุปกรณ์”)
                  และ/หรือใช้อุปกรณ์เพื่อเข้าถึงบริการของเรา
                </p>
                <p className="text-muted">
                  (m)
                  เมื่อคุณเชื่อมต่อบัญชีของคุณกับเว็บไซต์หรือแอปพลิเคชั่นของบุคคลภายนอก
                  แก่บัญชีผู้ใช้ของคุณกับเราผ่านทางไซต์ หรือ
                </p>
                <p className="text-muted">
                  (n) เมื่อคุณส่งข้อมูลส่วนบุคคลของคุณให้แก่เราด้วยเหตุผลใดก็ตาม
                </p>
                <p className="text-muted">
                  นโยบายดังกล่าวข้างต้นไม่ได้อ้างไว้โดยละเอียดถี่ถ้วนและเป็นเพียงตัวอย่างทั่วไปเมื่อเราเก็บรวบรวมข้อมูลส่วนบุคคลเกี่ยวกับคุณ
                </p>
                <p className="text-muted">
                  2.2 เราอาจเก็บรวบรวม ใช้ เปิดเผย
                  และ/หรือดำเนินการข้อมูลนี้เพื่อวัตถุประสงค์ต่างๆ
                  (ดังที่กำหนดไว้ด้านล่าง)
                </p>
                <p className="text-muted">
                  2.3
                  แอปพลิเคชั่นบนมือถือของเราอาจเก็บรวบรวมข้อมูลที่ถูกต้องแม่นยำเกี่ยวกับตำแหน่งที่ตั้งของอุปกรณ์เคลื่อนที่ของคุณโดยใช้เทคโนโลยี
                  เช่น GPS, Wi-Fi ฯลฯ เราเก็บรวบรวม ใช้ เปิดเผย
                  และ/หรือดำเนินการข้อมูลนี้เพื่อวัตถุประสงค์ใดวัตถุประสงค์หนึ่งหรือมากกว่านั้น
                  รวมถึงแต่ไม่จำกัดเพียง
                  บริการตามตำแหน่งที่ตั้งที่คุณร้องขอหรือมอบเนื้อหาที่เกี่ยวข้องให้แก่คุณตามตำแหน่งที่ตั้งของคุณ
                  หรืออนุญาตให้คุณแบ่งปันตำแหน่งที่ตั้งของคุณให้แก่ผู้ใช้อื่นๆ
                  โดยเป็นส่วนหนึ่งของบริการภายใต้แอปพลิเคชั่นบนมือถือของเรา
                  สำหรับอุปกรณ์เคลื่อนที่ส่วนใหญ่
                  คุณสามารถถอนสิทธิ์ที่จะให้เราได้รับข้อมูลเกี่ยวกับตำแหน่งที่ตั้งของคุณผ่านการตั้งค่าบนอุปกรณ์ของคุณ
                  หากคุณมีข้อสงสัยเกี่ยวกับวิธีปิดการใช้งานบริการเกี่ยวกับตำแหน่งที่ตั้งบนอุปกรณ์เคลื่อนที่ของคุณ
                  โปรดติดต่อผู้ให้บริการอุปกรณ์เคลื่อนที่หรือผู้ผลิตอุปกรณ์ของคุณ
                </p>
                <h5>3. DE LAMUN จะเก็บรวบรวมข้อมูลส่วนบุคคลใดไว้บ้าง</h5>
                <p className="text-muted">
                  3.1 ข้อมูลส่วนบุคคลที่ DE LAMUN
                  อาจเก็บรวบรวมนั้นจะรวมถึงแต่ไม่จำกัดเพียง:
                  <ul>
                    <li>ชี่อ</li>
                    <li>ที่อยู่อีเมล</li>
                    <li>วันเกิด</li>
                    <li>ที่อยู่ตามบัตรประชาชนหรือพาสปอร์ต</li>
                    <li>ที่อยู่สำหรับเรียกเก็บเงิน</li>
                    <li>ที่อยู่สำหรับการจัดส่ง</li>
                    <li>รายละเอียดบัญชีธนาคารและข้อมูลการชำระเงิน</li>
                    <li>หมายเลขติดต่อ</li>
                    <li>หมายเลขบัตรประชาชนหรือหมายเลขพาสปอร์ต</li>
                    <li>เพศ</li>
                    <li>ที่อยู่อินเทอร์เน็ตโปรโตคอล (IP)</li>
                    <li>
                      ข้อมูลอื่นใดเกี่ยวกับผู้ใช้เมื่อผู้ใช้ลงทะเบียนเข้าใช้บริการหรือไซต์ของเรา
                      และเมื่อผู้ใช้ใช้บริการหรือไซต์
                      รวมถึงข้อมูลที่เกี่ยวกับวิธีการใช้บริการหรือไซต์ของผู้ใช้
                    </li>
                    <li>
                      ประชาชนทั่วไปและกลุ่มที่คุณเชื่อมต่อหรืออาจเชื่อมต่อกันบนแพลตฟอร์มของเรา
                      และลักษณะที่คุณติดต่อสื่อสารกับบุคคลเหล่านั้น
                    </li>
                    <li>
                      ข้อมูลจากหรือเกี่ยวกับอุปกรณ์ที่คุณติดตั้งแอปพลิเคชั่นของเรา
                      และ/หรือใช้อุปกรณ์เพื่อเข้าถึงบริการของเรา และ/หรือไซต์
                    </li>
                    <li>
                      รายละเอียดบัญชีของคุณกับเว็บไซต์หรือแอปพลิเคชั่นของบุคคลภายนอกที่คุณได้เชื่อมต่อบัญชีผู้ใช้ของคุณกับเราผ่านทางไซต์
                    </li>
                    <li>ข้อมูลส่วนบุคคลที่คุณอาจให้แก่เราผ่านทางไซต์</li>
                  </ul>
                </p>
                <p className="text-muted">
                  3.2 ตามที่ระบุในส่วนที่ 2
                  เราอาจได้รับข้อมูลเกี่ยวกับคุณและกิจกรรมของคุณที่อยู่บนและไม่อยู่บนแพลตฟอร์ม
                  DE LAMUN จากบริษัทในกลุ่มและ/หรือหุ้นส่วนซึ่งเป็นบุคคลภายนอก
                  เช่น
                  ข้อมูลจากบริษัทในกลุ่มและ/หรือหุ้นส่วนที่เราร่วมกันเสนอบริการและ/หรือรายการส่งเสริมการขาย
                  หรือจากผู้โฆษณาเกี่ยวกับประสบการณ์หรือการติดต่อของคุณกับพวกเขา
                  รวมถึงข้อมูลเกี่ยวกับเว็บไซต์และแอปพลิเคชั่นที่คุณเข้าเยี่ยมชม
                  การที่คุณใช้บริการของเราบนเว็บไซต์และแอปพลิเคชั่นเหล่านั้น
                  เช่นเดียวกับข้อมูลผู้พัฒนาหรือผู้เผยแพร่บนแอปพลิเคชั่นหรือเว็บไซต์ที่ให้แก่คุณหรือเรา
                </p>
                <p className="text-muted">
                  3.4 เมื่อคุณใช้บัตรเครดิตเพื่อทำธุรกรรมบนไซต์
                  คุณกำลังให้ข้อมูลของคุณแก่ผู้ให้บริการภายนอก
                  ในการเชื่อมต่อนั้นข้อมูลที่คุณได้ให้แก่ผู้ให้บริการภายนอกจะอยู่ภายใต้นโยบายความเป็นส่วนตัวที่เกี่ยวข้องของผู้ให้บริการภายนอกนั้น
                  DE LAMUN
                  ไม่อาจเข้าถึงข้อมูลเช่นว่านั้นและไม่ได้เก็บรวบรวมรายละเอียดบัตรเครดิตของคุณ
                </p>
                <h5>4. การดูเว็บเพจ</h5>
                <p className="text-muted">
                  ในการเข้าถึงเว็บไซต์ส่วนใหญ่ด้วยอุปกรณ์ของคุณ
                  อุปกรณ์ของคุณจะส่งข้อมูลซึ่งอาจมีข้อมูลส่วนบุคคลของคุณซึ่งจะถูกบันทึกไว้โดยเซิร์ฟเวอร์ของเว็บเมื่อคุณเรียกดูเว็บของเรา
                  ซึ่งโดยทั่วไปจะรวมถึงแต่ไม่จำกัดเพียง ที่อยู่ IP
                  ของคอมพิวเตอร์ ระบบปฏิบัติการ ชื่อ/เวอร์ชันของเบราว์เซอร์
                  เว็บเพจที่อ้าง หน้าที่ร้องขอ วันที่/เวลา และบางครั้งอาจรวมถึง
                  "คุกกี้"
                  (ซึ่งคุณสามารถปิดใช้งานได้โดยกำหนดลักษณะอุปกรณ์ของคุณ)
                  เพื่อช่วยให้ไซต์จดจำการเข้าชมครั้งล่าสุดของคุณ
                  เมื่อคุณเข้าสู่ระบบ ข้อมูลนี้จะเชื่อมโยงกับบัญชีผู้ใช้ของคุณ
                  นอกจากนี้ข้อมูลนี้จะนำไปใช้ในเชิงสถิติที่ไม่ระบุชื่อเพื่อช่วยให้เราทำความเข้าใจเกี่ยวกับวิธีที่ผู้เยี่ยมชมใช้งานไซต์ของเรา
                </p>
                <h5>5. คุกกี้</h5>
                <p className="text-muted">
                  5.1 เราอาจมีการใช้ “คุกกี้” หรือคุณลักษณะอื่นๆ
                  ในบางคราวเพื่อช่วยให้เราหรือบุคคลภายนอกสามารถเก็บรวบรวมหรือแบ่งปันข้อมูล
                  เพื่อช่วยเราปรับปรุงไซต์และบริการที่เรานำเสนอ
                  หรือช่วยให้เรามอบบริการและคุณลักษณะใหม่ๆ ได้ “คุกกี้”
                  คือตัวระบุต่างๆ ที่เราส่งไปยังอุปกรณ์ของคุณ
                  เพื่อช่วยให้เราสามารถจดจำอุปกรณ์ของคุณ
                  และบอกเกี่ยวกับวิธีและเวลาในการใช้หรือเยี่ยมชมบริการหรือไซต์
                  จำนวนผู้ใช้ และเพื่อติดตามความเคลื่อนไหวภายในไซต์ของเรา
                  เราอาจเชื่อมโยงข้อมูลคุกกี้กับข้อมูลส่วนบุคคลของคุณ นอกจากนี้
                  คุกกี้ยังเชื่อมโยงกับข้อมูลเกี่ยวกับรายการที่คุณเลือกซื้อและบัญชีรายชื่อสินค้าที่คุณเข้าไปชมด้วย
                  ตัวอย่างเช่น
                  เราใช้ข้อมูลนี้เพื่อติดตามข้อมูลเกี่ยวกับรถเข็นสินค้าของคุณ
                  นอกจากนี้
                  ยังมีการใช้คุกกี้เพื่อมอบเนื้อหาที่เหมาะสมกับความสนใจของคุณโดยเฉพาะ
                  และเพื่อตรวจสอบการใช้งานไซต์
                </p>
                <p className="text-muted">
                  5.2
                  คุณอาจปฏิเสธการใช้คุกกี้ได้โดยเลือกการตั้งค่าที่เหมาะสมในอุปกรณ์ของคุณ
                  อย่างไรก็ดี โปรดจำไว้ว่าหากคุณปฏิเสธการใช้คุกกี้
                  คุณอาจไม่สามารถใช้ฟังก์ชันการทำงานของไซต์หรือบริการได้อย่างเต็มที่
                </p>
                <h5>6. การดูและดาวน์โหลดเนื้อหาและโฆษณา</h5>
                <p className="text-muted">
                  เมื่อคุณท่องเว็บเพจต่างๆ โดยใช้อุปกรณ์ของคุณ
                  ขณะที่คุณเข้าชมเนื้อหาและโฆษณาและเข้าใช้งานซอฟต์แวร์อื่นๆ
                  บนไซต์ของเราหรือผ่านบริการ
                  ข้อมูลเดียวกันส่วนใหญ่จะถูกส่งมาถึงเรา
                  (รวมถึงแต่ไม่จำกัดเพียงที่อยู่ IP ระบบปฏิบัติการ ฯลฯ)
                  แต่แทนที่จะส่งการเข้าชมหน้าต่างๆ ของคุณ
                  คอมพิวเตอร์จะส่งข้อมูลเกี่ยวกับเนื้อหา การโฆษณาที่ดู
                  และ/หรือซอฟต์แวร์ที่ติดตั้งโดยบริการและเว็บไซต์และเวลาให้แก่เรา
                </p>
                <h5>7. ชุมชนและการสนับสนุน</h5>
                <p className="text-muted">
                  7.1 เราให้การสนับสนุนด้านการบริการลูกค้าผ่านอีเมล SMS
                  และแบบฟอร์มความคิดเห็น เพื่อให้การสนับสนุนลูกค้า
                  เราจะขอที่อยู่อีเมลและหมายเลขติดต่อของคุณ
                  เราใช้เพียงข้อมูลที่ได้รับจากลูกค้าที่ขอรับการสนับสนุน
                  รวมถึงแต่ไม่จำกัดเพียง ที่อยู่อีเมล
                  สำหรับการบริการสนับสนุนลูกค้า
                  และเราจะไม่ส่งหรือแบ่งปันข้อมูลนี้กับบุคคลภายนอก
                </p>
                <p className="text-muted">
                  7.2 คุณยังสามารถให้ความเห็นในตัวสินค้าหรือบริการ
                  และอ่านความเห็นที่ผู้ใช้คนอื่นได้ให้ข้อมูลไว้ได้ด้วย
                  บริการนี้รวมถึงการส่งคำแนะนำสินค้าไปยังเพื่อนหรือคนรู้จักของคุณ
                  และเราจะรักษาข้อมูล ได้แก่ ID ผู้ใช้ รายชื่อผู้ติดต่อ
                  และข้อความสถานะของคุณ นอกจากนี้
                  บริการเช่นนี้และที่คล้ายคลึงกันในอนาคตอาจกำหนดให้เราต้องรักษา
                  ID ผู้ใช้และรหัสผ่านของคุณ
                </p>
                <h5>8. การสำรวจ</h5>
                <p className="text-muted">
                  บางครั้ง เราอาจร้องขอข้อมูลจากผู้ใช้ผ่านการสำรวจต่างๆ
                  การเข้าร่วมในการสำรวจเหล่านี้ขึ้นอยู่กับความสมัครใจทั้งสิ้น
                  ดังนั้นคุณจึงสามารถเลือกได้ว่าจะเปิดเผยข้อมูลของคุณแก่เราหรือไม่
                  ข้อมูลที่ร้องขออาจรวมถึงแต่ไม่จำกัดเพียง ข้อมูลการติดต่อ
                  (ได้แก่ ที่อยู่อีเมล) และข้อมูลทางประชากรศาสตร์ (ได้แก่
                  ความสนใจ หรือระดับอายุ)
                  ข้อมูลที่ได้จากการสำรวจนี้จะนำมาใช้เพื่อตรวจสอบและปรับปรุงการใช้งานและความพึงพอใจในการบริการ
                  และจะไม่ถูกส่งให้แก่บุคคลภายนอก ยกเว้น
                  ผู้รับจ้างที่ช่วยเราในการบริหารจัดการและดำเนินการสำรวจ
                </p>
                <h5>9. เรามีวิธีการใช้ข้อมูลที่คุณให้แก่เราอย่างไรบ้าง</h5>
                <p className="text-muted">
                  9.1 เราอาจเก็บรวบรวม ใช้ เปิดเผย
                  และ/หรือดำเนินการข้อมูลส่วนบุคคลของคุณเพื่อวัตถุประสงค์อย่างน้อยหนึ่งวัตถุประสงค์ดังต่อไปนี้
                </p>
                <p className="text-muted">
                  (ก)
                  เพื่อพิจารณาและ/หรือดำเนินการการยื่นเรื่อง/ธุรกรรมของคุณกับเรา
                  หรือธุรกรรมหรือการติดต่อสื่อสารของคุณกับบุคคลภายนอกผ่านบริการ
                </p>
                <p className="text-muted">
                  (ข) เพื่อจัดการ ดำเนินการ ให้
                  และ/หรือบริหารการใช้งานของคุณและ/หรือเพื่อเข้าถึงบริการและเว็บไซต์ของเรา
                  รวมถึงความสัมพันธ์และบัญชีผู้ใช้ของคุณกับเรา
                </p>
                <p className="text-muted">
                  (ค) เพื่อจัดการ ดำเนินการ บริหาร และจัดหาบริการแก่คุณ
                  รวมถึงช่วยอำนวยความสะดวกในการจัดเตรียมบริการของเรา
                  รวมถึงแต่ไม่จำกัดเพียง การจดจำความต้องการของคุณ
                </p>
                <p className="text-muted">
                  (ง)
                  ปรับแต่งประสบการณ์ของคุณผ่านบริการโดยแสดงเนื้อหาตามความสนใจและความต้องการของคุณ
                  พร้อมให้วิธีที่รวดเร็วในการเข้าถึงบัญชีของคุณและส่งข้อมูลมายังเรา
                  ซึ่งช่วยให้เราสามารถติดต่อกับคุณได้หากจำเป็น
                </p>
                <p className="text-muted">
                  (จ) เมื่อคุณใช้แอปพลิเคชั่น
                  เว็บไซต์หรือบริการอื่นของบุคคลภายนอกที่ใช้หรือรวมกับบริการของเรา
                  บุคคลภายนอกนั้นอาจรับข้อมูลเกี่ยวกับสิ่งที่คุณโพสต์หรือแบ่งปัน
                </p>
                <p className="text-muted">
                  (ฉ) เพื่อตอบรับ ดำเนินการ จัดการ หรือทำธุรกรรมให้เสร็จสมบูรณ์
                  และ/หรือทำตามการร้องขอเกี่ยวกับผลิตภัณฑ์และบริการของคุณ
                  และแจ้งให้คุณทราบเกี่ยวกับปัญหาด้านการบริการและการดำเนินการเกี่ยวกับบัญชี
                  ที่มีความผิดปกติ
                </p>
                <p className="text-muted">
                  (ช)
                  เพื่อบังคับใช้เงื่อนไขการให้บริการหรือข้อตกลงสิทธิ์การใช้งานสำหรับผู้ใช้ที่เกี่ยวข้อง
                </p>
                <p className="text-muted">
                  (ซ) เพื่อป้องกันความปลอดภัยและสิทธิ์ ทรัพย์สินส่วนบุคคล
                  หรือความปลอดภัยของบุคคลอื่น
                </p>
                <p className="text-muted">
                  (ฌ) เพื่อระบุตัวตนและ/หรือการตรวจสอบความถูกต้อง
                </p>
                <p className="text-muted">
                  (ญ)
                  เพื่อรักษาและควบคุมการอัปเดตซอฟต์แวร์และ/หรือการอัปเดตอื่นๆ
                  และให้การสนับสนุนที่จำเป็นในบางคราวเพื่อให้แน่ใจว่าบริการของเราจะเป็นไปอย่างราบรื่น
                </p>
                <p className="text-muted">
                  (ฎ) เพื่อจัดการหรืออำนวยความสะดวกในการให้บริการลูกค้า
                  ปฏิบัติตามคำสั่งของคุณ จัดการหรือตอบข้อซักถามที่ได้รับ
                  (หรืออ้างว่าได้รับ) จากคุณหรือในนามของคุณ
                </p>
                <p className="text-muted">
                  (ฏ) เพื่อติดต่อหรือสื่อสารกับคุณผ่านการโทรด้วยเสียง ข้อความ
                  และ/หรือข้อความแฟกซ์ อีเมล และ/หรือการส่งไปรษณีย์
                  หรือในรูปแบบอื่นใด
                  เพื่อวัตถุประสงค์ในการบริหารและ/หรือจัดการความสัมพันธ์ระหว่างคุณกับเราหรือการใช้บริการของเรา
                  เช่นว่าแต่ไม่จำกัดเพียง
                  การสื่อสารข้อมูลด้านการจัดการที่เกี่ยวเนื่องกับบริการของเรากับคุณ
                  คุณรับทราบและยอมรับว่าการสื่อสารดังกล่าวของเรานั้นอาจเป็นด้วยวิธีการส่งจดหมายโต้ตอบ
                  เอกสารหรือการแจ้งประกาศแก่คุณ
                  ซึ่งอาจเกี่ยวข้องกับการเปิดเผยข้อมูลส่วนบุคคลของคุณบางประการบนหน้าซอง/หีบห่อจดหมายด้านนอก
                  เพื่อการจัดส่งสิ่งดังกล่าว
                </p>
                <p className="text-muted">
                  (ฐ)
                  เพื่อแจ้งให้คุณทราบเมื่อผู้ใช้คนอื่นส่งข้อความส่วนตัวหรือโพสต์ความคิดเห็นให้คุณบนไซต์
                </p>
                <p className="text-muted">
                  (ฑ) เพื่อ ดำเนินการศึกษาวิจัย วิเคราะห์ และพัฒนากิจกรรมต่างๆ
                  (รวมถึงแต่ไม่จำกัดเพียง การวิเคราะห์ข้อมูล การสำรวจ
                  การพัฒนาผลิตภัณฑ์และบริการ และ/หรือ การทำแฟ้มประวัติ) เพื่อ
                  วิเคราะห์การใช้บริการ
                  เพื่อนำไปปรับปรุงบริการหรือผลิตภัณฑ์ของเรา
                  และ/หรือช่วยเสริมประสบการณ์ของลูกค้า
                </p>
                <p className="text-muted">
                  (ฒ) เพื่อให้สามารถตรวจสอบและทำการสำรวจและอื่นๆ
                  เพื่อตรวจสอบขนาดและองค์ประกอบของกลุ่มเป้าหมายของเรา
                  และทำความเข้าใจเกี่ยวกับประสบการณ์ที่ลูกค้าได้รับจากบริการของ
                  DE LAMUN
                </p>
                <p className="text-muted">
                  (ณ)
                  ในกรณีที่คุณให้ความยินยอมให้เราส่งข้อมูลการตลาดและการส่งเสริมการขาย
                  และเอกสารที่เกี่ยวข้องกับผลิตภัณฑ์และ/หรือบริการ
                  (รวมถึงแต่ไม่จำกัดเพียง
                  ผลิตภัณฑ์และ/หรือบริการของบุคคลที่สามซึ่ง DE LAMUN
                  อาจทำงานร่วมกันหรือมีข้อผูกพันต่อกัน)
                  เพื่อการตลาดและในเรื่องดังกล่าวนี้
                  โดยผ่านรูปแบบการติดต่อสื่อสารต่างๆ ได้แก่ การส่งไปรษณีย์
                  การบริการตามตำแหน่งที่ตั้งหรือรูปแบบอื่นใด ซึ่ง DE LAMUN
                  (และ/หรือบริษัทในเครือหรือบริษัทที่เกี่ยวข้อง) อาจ จำหน่าย
                  ทำการตลาด หรือส่งเสริมการขาย
                  ไม่ว่าผลิตภัณฑ์หรือบริการดังกล่าวจะมีอยู่ในขณะนี้หรือจะเกิดขึ้นในอนาคตหรือไม่ก็ตาม
                  หากคุณพำนักอยู่ในประเทศสิงคโปร์
                  ในกรณีการส่งข้อมูลการตลาดหรือการส่งเสริมการขายให้แก่คุณผ่านการโทรด้วยเสียง
                  SMS/MMS หรือแฟกซ์ไปยังหมายเลขแฟกซ์ของประเทศสิงคโปร์
                  เราจะไม่กระทำการดังกล่าวยกเว้นแต่เราได้ปฏิบัติตามข้อกำหนดของกฎหมายเกี่ยวกับการคุ้มครองข้อมูลส่วนบุคคลของประเทศสิงคโปร์เกี่ยวกับการใช้รูปแบบการสื่อสารดังกล่าวในการส่งข้อมูลการตลาดนั้นให้แก่คุณ
                  หรือคุณได้แสดงความยินยอมในเรื่องดังกล่าวแล้ว
                </p>
                <p className="text-muted">
                  (ด) เพื่อตอบสนองต่อกระบวนการทางกฎหมาย
                  หรือปฏิบัติตามหรือเป็นไปตามกฎหมายที่บังคับใช้
                  ข้อกำหนดของภาครัฐหรือตามกฎข้อบังคับภายใต้อำนาจการพิจารณาที่เกี่ยวข้อง
                  รวมถึงแต่ไม่จำกัดเพียง
                  การดูแลให้เป็นไปตามข้อกำหนดเพื่อทำให้การเปิดเผยข้อมูลนั้นเป็นไปตามข้อกำหนดของกฎหมายที่
                  DE LAMUN
                  หรือบริษัทที่เกี่ยวข้องหรือบริษัทในเครือต้องปฏิบัติตาม
                </p>
                <p className="text-muted">
                  (ต)
                  เพื่อจัดทำข้อมูลสถิติและงานวิจัยเพื่อการรายงานภายในองค์กรและตามกฎหมาย
                  และ/หรือตามข้อกำหนดเกี่ยวกับการจัดเก็บบันทึก
                </p>
                <p className="text-muted">
                  (ถ)
                  เพื่อปฏิบัติตามขั้นตอนการตรวจสอบประวัติความเป็นมาทางธุรกิจหรือกิจกรรมการคัดกรองอื่นใด
                  (รวมถึงแต่ไม่จำกัดเพียง การตรวจสอบประวัติความเป็นมา)
                  ซึ่งเป็นไปตามข้อผูกพันทางกฎหมายหรือตามกฎข้อบังคับ
                  หรือกระบวนการจัดการความเสี่ยงของเราซึ่งกฎหมายได้กำหนดไว้หรือที่เราได้เป็นผู้นำมาใช้
                </p>
                <p className="text-muted">
                  (ท) เพื่อตรวจสอบบริการของเราหรือ ธุรกิจของ DE LAMUN
                </p>
                <p className="text-muted">
                  (ธ) เพื่อป้องกันและตรวจสอบการฉ้อโกง กิจกรรมที่ผิดกฎหมาย
                  การละเว้นหรือการประพฤติผิด
                  ไม่ว่าจะเกี่ยวข้องกับการใช้บริการของเราหรือกิจกรรมอื่นใดอันเกิดจากความสัมพันธ์ของคุณกับเรา
                  และไม่ว่าจะมีความสงสัยในสิ่งที่ได้กล่าวถึงข้างต้นหรือไม่ก็ตาม
                </p>
                <p className="text-muted">
                  (น) เพื่อจัดเก็บ โฮสต์ สำรองข้อมูล
                  (ไม่ว่าจะเพื่อการกู้คืนจากความเสียหายหรือเหตุอื่นใด)
                  ข้อมูลส่วนบุคคลของคุณ ไม่ว่าจะอยู่ในหรือนอกอำนาจการพิจารณา
                </p>
                <p className="text-muted">
                  (บ)
                  เพื่อจัดการและ/หรือช่วยอำนวยความสะดวกให้แก่ธุรกรรมเกี่ยวกับทรัพย์สินของธุรกิจหรือธุรกรรมเกี่ยวกับทรัพย์สินของธุรกิจที่มีศักยภาพ
                  ซึ่งธุรกรรมดังกล่าวเกี่ยวข้องกับ DE LAMUN
                  ในฐานะผู้มีส่วนร่วมหรือเกี่ยวข้องกับบริษัทที่เกี่ยวข้องหรือบริษัทในเครือของ
                  DE LAMUN ในฐานะผู้มีส่วนร่วมเท่านั้น หรือเกี่ยวข้องกับ DE
                  LAMUN และ/หรือบริษัทหรือบริษัทในเครือที่เกี่ยวข้องของ DE LAMUN
                  อย่างน้อยหนึ่งแห่งในฐานะผู้มีส่วนร่วม
                  และอาจมีองค์กรภายนอกที่เป็นผู้มีส่วนร่วมในธุรกรรมดังกล่าว
                  "ธุรกรรมเกี่ยวกับทรัพย์สินของธุรกิจ" หมายถึง การซื้อ ขาย
                  ให้เช่า ควบรวม การควบ หรือการซื้อกิจการอื่นใด
                  การจำหน่ายจ่ายโอนหรือการให้เงินทุนแก่องค์กรหรือส่วนหนึ่งส่วนใดขององค์กรหรือธุรกิจหรือทรัพย์สินอื่นใดขององค์กร
                  และ/หรือ
                </p>
                <p className="text-muted">
                  (ป) วัตถุประสงค์อื่นใดที่เราได้แจ้งแก่คุณ ณ
                  เวลาที่ได้รับความยินยอมจากคุณ (รวมเรียกว่า “วัตถุประสงค์”)
                </p>
                <p className="text-muted">
                  9.2 เนื่องจากวัตถุประสงค์ที่เราจะเก็บรวบรวม ใช้ เปิดเผย
                  และ/หรือดำเนินการข้อมูลส่วนบุคคลของคุณขึ้นอยู่กับสถานการณ์เฉพาะหน้า
                  วัตถุประสงค์ดังกล่าวอาจไม่ปรากฎในข้างต้น อย่างไรก็ตาม
                  เราจะแจ้งให้คุณทราบถึงวัตถุประสงค์ดังกล่าว ณ
                  เวลาที่ขอรับความยินยอมจากคุณ
                  เว้นแต่การดำเนินการข้อมูลที่เกี่ยวข้องโดยไม่ได้รับความยินยอมจากคุณนั้นเป็นไปตามที่กฎหมายเกี่ยวกับการคุ้มครองข้อมูลส่วนบุคคลกำหนดไว้
                </p>
                <h5>10. การแบ่งปันข้อมูลจากบริการ</h5>
                <p className="text-muted">
                  บริการของเราให้ผู้ใช้สามารถแบ่งปันข้อมูลส่วนบุคคลกับผู้อื่นเพื่อการทำธุรกรรมได้ในเกือบทุกสถานการณ์
                  โดยที่ DE LAMUN ไม่มีส่วนเกี่ยวข้องด้วยแต่อย่างใด
                  ในธุรกรรมทั่วไป ผู้ใช้อาจได้เข้าถึงชื่อ ID ผู้ใช้ ที่อยู่อีเมล
                  และข้อมูลการติดต่อและข้อมูลการส่งไปรษณีย์ของกันและกัน
                  เงื่อนไขการให้บริการของเรากำหนดให้ผู้ใช้ที่ครอบครองข้อมูลส่วนบุคคลของผู้ใช้อีกคนหนึ่ง
                  (“ฝ่ายผู้รับ”) จะต้อง (i)
                  ปฏิบัติตามกฎหมายเกี่ยวกับการคุ้มครองข้อมูลส่วนบุคคลที่เกี่ยวข้องทั้งหมด
                  (ii) อนุญาตให้ผู้ใช้อื่น (“ฝ่ายผู้เปิดเผย”)
                  ลบตนเองออกจากฐานข้อมูลของฝ่ายผู้รับ และ (iii)
                  อนุญาตให้ฝ่ายผู้เปิดเผยตรวจสอบว่าฝ่ายผู้รับมีการเก็บรวบรวมข้อมูลใดบ้างเกี่ยวกับตน
                </p>
                <h5>
                  11. DE LAMUN คุ้มครองข้อมูลส่วนบุคคลของลูกค้าอย่างไรบ้าง
                </h5>
                <p className="text-muted">
                  เรานำมาตรการด้านความปลอดภัยต่างๆ
                  มาใช้เพื่อให้แน่ใจว่าข้อมูลส่วนบุคคลของคุณที่อยู่ในระบบของเรานั้นมีความปลอดภัย
                  ข้อมูลส่วนบุคคลของผู้ใช้จะถูกเก็บไว้ในเครือข่ายที่มีความปลอดภัย
                  และสามารถเข้าถึงได้โดยพนักงานจำนวนจำกัดเท่านั้น
                  ซึ่งจะเป็นบุคคลที่มีสิทธิพิเศษในการเข้าถึงระบบดังกล่าว
                  เราจะรักษาข้อมูลส่วนบุคคลของคุณตามกฎหมายเกี่ยวกับการคุ้มครองข้อมูลส่วนบุคคลและ/หรือกฎหมายอื่นที่เกี่ยวข้อง
                  ซึ่งเราจะทำลายหรือไม่ระบุชื่อข้อมูลส่วนบุคคลของคุณทันทีที่มีเหตุผลอันเชื่อได้ว่า
                  (i)
                  วัตถุประสงค์ของการเก็บรวบรวมข้อมูลส่วนบุคคลนั้นไม่ตรงกับการรักษาข้อมูลส่วนบุคคลดังกล่าวไว้อีกต่อไป
                  และ (ii)
                  ไม่จำเป็นต้องรักษาข้อมูลส่วนบุคคลดังกล่าวเพื่อวัตถุประสงค์ทางกฎหมายหรือธุรกิจอีกต่อไป
                  หากคุณหยุดการใช้งานไซต์
                  หรือสิทธิการใช้งานไซต์และ/หรือบริการของคุณสิ้นสุดลง
                  เราอาจจัดเก็บ ใช้
                  และ/หรือเปิดเผยข้อมูลส่วนบุคคลต่อไปได้ตามกฎหมายเกี่ยวกับการคุ้มครองข้อมูลส่วนบุคคลและข้อผูกพันของเราภายใต้กฎหมายเกี่ยวกับการคุ้มครองข้อมูลส่วนบุคคลนี้
                  ภายใต้กฎหมายที่บังคับใช้
                  เราอาจทำลายข้อมูลส่วนบุคคลของคุณอย่างปลอดภัยโดยไม่ต้องแจ้งให้ทราบล่วงหน้า
                </p>
                <h5>
                  12. DE LAMUN
                  เปิดเผยข้อมูลที่รวบรวมจากผู้เยี่ยมชมให้แก่บุคคลภายนอกหรือไม่
                </h5>
                <p className="text-muted">
                  12.1 ในการดำเนินธุรกิจของเรา
                  เราอาจจำเป็นต้องเปิดเผยข้อมูลส่วนบุคคลของคุณให้แก่ผู้ให้บริการซึ่งเป็นบุคคลภายนอก
                  ตัวแทน และ/หรือบริษัทในเครือ หรือบริษัทที่เกี่ยวข้องของเรา
                  และ/หรือบุคคลที่สามอื่นๆ
                  ไม่ว่าจะมีสถานที่ตั้งอยู่ในประเทศหรือนอกประเทศสิงคโปร์
                  เพื่อวัตถุประสงค์ดังที่ระบุข้างต้นอย่างน้อยหนึ่งวัตถุประสงค์
                  ผู้ให้บริการซึ่งเป็นบุคคลภายนอก ตัวแทน และ/หรือบริษัทในเครือ
                  หรือบริษัทที่เกี่ยวข้องของเรา และ/หรือบุคคลภายนอกอื่นๆ
                  ดังกล่าวนั้นอาจดำเนินการข้อมูลส่วนบุคคลของคุณในนามของเราหรือในลักษณะอื่นใดเพื่อวัตถุประสงค์ดังที่ระบุข้างต้นอย่างน้อยหนึ่งวัตถุประสงค์
                  บุคคลภายนอกดังกล่าวรวมถึงแต่ไม่จำกัดเพียง:
                </p>
                <p className="text-muted">
                  (a) บริษัทในเครือ บริษัทในกลุ่มและบริษัทที่เกี่ยวข้องของเรา
                </p>
                <p className="text-muted">
                  (b) ผู้รับจ้าง ตัวแทน ผู้ให้บริการ และบุคคลภายนอกอื่นๆ
                  ที่เราใช้เพื่อสนับสนุนธุรกิจของเรา
                  บุคคลเหล่านี้รวมถึงแต่ไม่จำกัดเพียง
                  ผู้ให้บริการด้านการบริหารจัดการหรือด้านอื่นๆ อันได้แก่
                  บริษัทรับส่งจดหมาย บริษัทที่ให้บริการด้านโทรคมนาคม
                  บริษัทที่ให้บริการด้านเทคโนโลยีสารสนเทศ และศูนย์ข้อมูล
                </p>
                <p className="text-muted">
                  (c) ผู้ซื้อ ผู้รับโอนหรือผู้สืบตำแหน่งในกรณีควบรวมกิจการ
                  ถอนทุน ปรับโครงสร้าง จัดระเบียบองค์กรใหม่ เลิกกิจการ
                  หรือการขายหรือโอนสินทรัพย์บางส่วนหรือทั้งหมดของ DE LAMUN
                  ไม่ว่าจะเป็นการดำเนินงานต่อเนื่องหรือเป็นส่วนหนึ่งของการล้มละลาย
                  การเลิกกิจการทั้งหมด หรือการดำเนินการที่คล้ายกัน
                  ซึ่งข้อมูลส่วนบุคคลเกี่ยวกับผู้ใช้บริการของเราที่ DE LAMUN
                  ถือครองอยู่เป็นส่วนหนึ่งของสินทรัพย์ที่ถ่ายโอนไป
                </p>
                <p className="text-muted">
                  (d) ให้แก่คู่สัญญาในธุรกรรมเกี่ยวกับทรัพย์สินของธุรกิจที่ DE
                  LAMUN
                  หรือบริษัทในเครือหรือบริษัทที่เกี่ยวข้องของเรามีส่วนเกี่ยวข้อง
                  และ/หรือ
                </p>
                <p className="text-muted">
                  (e)
                  บุคคลภายนอกที่เราได้เปิดเผยข้อมูลให้เพื่อวัตถุประสงค์อย่างน้อยหนึ่งวัตถุประสงค์
                  และบุคคลภายนอกดังกล่าวอาจเก็บรวบรวมและประมวลผลข้อมูลส่วนบุคคลของคุณเพื่อวัตถุประสงค์อย่างน้อยหนึ่งวัตถุประสงค์
                </p>
                <p className="text-muted">
                  12.2 เราอาจจำเป็นต้องแบ่งปัน
                  ข้อมูลเชิงสถิติและประชากรศาสตร์เกี่ยวกับผู้ใช้และการใช้บริการของผู้ใช้ของเราให้แก่ผู้จัดหาโฆษณาและการเขียนโปรแกรม
                  ข้อมูลนี้จะไม่รวมข้อมูลใดๆ
                  ที่อาจนำไปใช้เพื่อระบุตัวตนของคุณโดยเฉพาะ
                  หรือเพื่อค้นข้อมูลส่วนบุคคลเกี่ยวกับตัวคุณ
                </p>
                <p className="text-muted">
                  12.3 เพื่อหลีกเลี่ยงข้อสงสัย
                  ในกรณีที่กฎหมายเกี่ยวกับการคุ้มครองข้อมูลส่วนบุคคลหรือกฎหมายที่เกี่ยวข้องอื่นๆ
                  อนุญาตให้องค์กร เช่นองค์กรของเรา เก็บรวบรวม ใช้
                  หรือเปิดเผยข้อมูลส่วนบุคคลของคุณโดยไม่ต้องรับความยินยอมจากคุณ
                  ให้นำการอนุญาตโดยกฎหมายข้างต้นมาใช้บังคับต่อไป
                </p>
                <p className="text-muted">
                  12.4
                  บุคคลภายนอกอาจปิดกั้นหรือเข้าถึงข้อมูลส่วนบุคคลที่ส่งต่อไปยังหรืออยู่ในไซต์อย่างผิดกฎหมาย
                  เทคโนโลยีอาจทำงานผิดปกติหรือไม่ทำงานตามที่คาดหวัง
                  หรือบุคคลใดอาจเข้าถึง ใช้ข้อมูลอย่างไม่เหมาะสม
                  หรือใช้ข้อมูลในทางที่ผิด โดยที่ไม่ได้มีสาเหตุมาจากเรา
                  ถึงกระนั้นก็ตาม
                  เราจะใช้มาตรการด้านความปลอดภัยที่สมเหตุสมผลเพื่อคุ้มครองข้อมูลส่วนบุคคลของคุณตามที่กำหนดไว้ภายใต้กฎหมายเกี่ยวกับการคุ้มครองข้อมูลส่วนบุคคล
                  อย่างไรก็ดี
                  เราไม่สามารถรับประกันถึงความปลอดภัยได้โดยสิ้นเชิงในกรณีที่มิอาจหลีกเลี่ยงได้
                  เช่นว่าแต่ไม่จำกัดเพียง
                  เมื่อการเปิดเผยข้อมูลโดยไม่ได้รับอนุญาตเกิดจากการเจาะข้อมูลที่ประสงค์ร้ายที่มีความซับซ้อนโดยผู้ที่ไม่พอใจ
                  โดยที่ไม่ได้มีสาเหตุมาจากเรา
                </p>
                <h5>13. ข้อมูลของเด็ก</h5>
                <p className="text-muted">
                  บริการนี้ไม่เหมาะสำหรับเด็กที่มีอายุต่ำกว่า 10 ปี
                  เราไม่ได้จงใจเก็บรวบรวมหรือรักษาข้อมูลส่วนบุคคลหรือข้อมูลที่ไม่สามารถระบุตัวตนใดๆ
                  จากผู้ซึ่งมีอายุต่ำกว่า 10 ปี
                  อีกทั้งในส่วนหนึ่งส่วนใดของไซต์หรือบริการอื่นๆ
                  จะไม่มีเนื้อหาสำหรับเด็กที่มีอายุต่ำกว่า 10 ปี
                  เราจะทำการปิดบัญชีที่เด็กใช้และจะถอนและ/หรือลบข้อมูลส่วนบุคคลใดๆ
                  ซึ่งเราเชื่อว่าถูกส่งมาจากเด็กที่มีอายุต่ำกว่า 10 ปี
                </p>
                <h5>14. ข้อมูลที่รวบรวมโดยบุคคลภายนอก</h5>
                <p className="text-muted">
                  14.1 ไซต์ของเราใช้ Google Analytics
                  ซึ่งเป็นบริการวิเคราะห์เว็บที่ให้บริการโดย Google, Inc.
                  (“Google”) Google Analytics ใช้คุกกี้
                  ซึ่งเป็นไฟล์ข้อความที่เก็บไว้ในคอมพิวเตอร์ของคุณเพื่อช่วยเว็บไซต์วิเคราะห์วิธีการใช้งานของผู้ใช้
                  ข้อมูลที่คุกกี้สร้างขึ้นเกี่ยวกับการใช้งานเว็บไซต์
                  (รวมถึงที่อยู่ IP ของคุณ) จะถูกส่งและจัดเก็บบนเซิร์ฟเวอร์โดย
                  Google ในประเทศสหรัฐอเมริกา Google
                  จะใช้ข้อมูลนี้เพื่อประเมินการใช้งานเว็บไซต์
                  รวบรวมรายงานเกี่ยวกับกิจกรรมที่เกิดขึ้นในเว็บไซต์สำหรับผู้ให้บริการเว็บไซต์
                  และให้บริการด้านอื่นๆ
                  ที่เกี่ยวข้องกับกิจกรรมของเว็บไซต์และการใช้งานอินเทอร์เน็ต
                  Google
                  ยังอาจส่งข้อมูลนี้ให้แก่บุคคลภายนอกตามที่กฎหมายได้กำหนดไว้
                  หรือเมื่อบุคคลภายนอกดังกล่าวดำเนินการประมวลผลข้อมูลในนามของ
                  Google Google จะไม่เชื่อมโยงที่อยู่ IP ของคุณกับข้อมูลอื่นที่
                  Google เก็บรักษาไว้
                </p>
                <p className="text-muted">
                  14.2 เราและบุคคลภายนอก
                  อาจให้บริการดาวน์โหลดแอปพลิเคชั่นซอฟต์แวร์ในบางคราวเพื่อให้คุณสามารถใช้บริการหรือใช้งานผ่านบริการ
                  แอปพลิเคชั่นเหล่านี้อาจเข้าถึงโดยแยกกัน
                  และอนุญาตให้บุคคลภายนอกเรียกดูข้อมูลที่ระบุตัวตนของคุณ ได้แก่
                  ชื่อ ID ผู้ใช้ และที่อยู่ IP ของคอมพิวเตอร์ของคุณ
                  หรือข้อมูลอื่นใด เช่น คุกกี้ ที่คุณอาจได้ติดตั้งไว้ก่อนหน้านี้
                  หรือที่แอปพลิเคชั่นหรือเว็บไซต์ของบุคคลภายนอกได้ติดตั้งไว้ให้แก่คุณ
                  นอกจากนี้
                  แอปพลิเคชั่นเหล่านี้อาจขอให้คุณให้ข้อมูลเพิ่มเติมแก่บุคคลภายนอกโดยตรง
                  ผลิตภัณฑ์หรือบริการของบุคคลภายนอกที่จัดหามาผ่านแอปพลิเคชั่นเหล่านี้ไม่ใช่ผลิตภัณฑ์หรือบริการของ
                  DE LAMUN หรือ DE LAMUN ไม่ได้เป็นผู้ควบคุม
                  ขอแนะนำให้อ่านข้อกำหนดและนโยบายอื่นๆ
                  ที่เผยแพร่โดยบุคคลภายนอกดังกล่าวบนเว็บไซต์เหล่านั้นหรือเว็บไซต์อื่นๆ
                </p>
                <h5>
                  15.
                  การปฏิเสธความรับผิดชอบเกี่ยวกับความปลอดภัยและไซต์ของบุคคลภายนอก
                </h5>
                <p className="text-muted">
                  15.1
                  เราไม่รับประกันว่าข้อมูลส่วนบุคคลและ/หรือข้อมูลอื่นที่คุณให้แก่ไซต์ของบุคคลภายนอกจะมีความปลอดภัย
                  เรานำมาตรการด้านความปลอดภัยต่างๆ
                  มาใช้เพื่อรักษาความปลอดภัยให้กับข้อมูลส่วนบุคคลของคุณซึ่งอยู่ในความครอบครองหรือควบคุมของเรา
                  ข้อมูลส่วนบุคคลของผู้ใช้จะถูกเก็บไว้ในเครือข่ายที่มีความปลอดภัย
                  และสามารถเข้าถึงได้โดยพนักงานจำนวนจำกัดเท่านั้น
                  ซึ่งจะเป็นบุคคลที่มีสิทธิพิเศษในการเข้าถึงระบบดังกล่าว
                  และต้องปกป้องข้อมูลส่วนบุคคลที่เป็นความลับ
                  เมื่อคุณส่งคำสั่งซื้อหรือเข้าถึงข้อมูลส่วนบุคคลของคุณ
                  เราขอเสนอให้ใช้เซิร์ฟเวอร์ที่มีความปลอดภัย
                  ข้อมูลส่วนบุคคลหรือข้อมูลที่ละเอียดอ่อนทั้งหมดที่คุณให้นั้นจะถูกนำไปเข้ารหัสไว้ในฐานข้อมูลของเราเพื่อให้เข้าถึงได้เฉพาะตามที่ระบุข้างต้นเท่านั้น
                </p>
                <p className="text-muted">
                  15.2 ด้วยความพยายามที่จะเพิ่มประโยชน์ให้แก่คุณ
                  เราอาจเลือกเว็บไซต์ของบุคคลภายนอกต่างๆ
                  ที่จะเชื่อมโยงหรือติดตั้งภายในไซต์
                  เรายังอาจเข้าร่วมในการพัฒนาแบรนด์ร่วมหรือสร้างความสัมพันธ์อื่นใด
                  เพื่อนำเสนอธุรกรรมอีคอมเมิร์ซ และ/หรือบริการและคุณลักษณะอื่นๆ
                  แก่ผู้เข้าชม
                  ไซต์ที่เชื่อมโยงเหล่านี้จะมีนโยบายความเป็นส่วนตัวและการจัดการด้านความปลอดภัยแยกต่างหากแบบอิสระ
                  แม้ว่าบุคคลภายนอกจะมีความเกี่ยวข้องกับเรา
                  แต่เราไม่มีสิทธิในการควบคุมไซต์ที่เชื่อมโยงเหล่านี้ได้
                  เนื่องจากแต่ละไซต์จะมีข้อปฏิบัติด้านความเป็นส่วนตัวและการเก็บรวบรวมข้อมูลแยกต่างหากเป็นอิสระจากเรา
                  ข้อมูลที่รวบรวมขึ้นจากหุ้นส่วนที่มีแบรนด์ร่วมกับเราหรือเว็บไซต์ของบุคคลภายนอก
                  (แม้ว่าจะนำเสนอในหรือผ่านเว็บไซต์ของเรา) อาจไม่ได้รับมาจากเรา
                </p>
                <p className="text-muted">
                  15.3 ดังนั้นเราจึงไม่ต้องรับผิดชอบหรือรับผิดในเนื้อหา
                  การจัดการด้านความปลอดภัย (หรือการไม่ได้รับความปลอดภัย)
                  และกิจกรรมต่างๆ ของไซต์ที่เชื่อมโยงเหล่านี้
                  ไซต์ที่เชื่อมโยงเหล่านี้มีไว้เพื่อความสะดวกของคุณ
                  และให้คุณเข้าใช้งานด้วยความรับผิดชอบของคุณเอง อย่างไรก็ตาม
                  เราต้องการปกป้องความสมบูรณ์ของไซต์และลิงก์ที่อยู่ในแต่ละไซต์ของเรา
                  และรับฟังข้อคิดเห็นเกี่ยวกับไซต์ที่เชื่อมโยงเหล่านี้
                  (รวมถึงแต่ไม่จำกัดเพียง หากลิงก์เฉพาะไม่ทำงาน)
                </p>
                <h5>16. DE LAMUN จะถ่ายโอนข้อมูลของคุณไปต่างประเทศหรือไม่</h5>
                <p className="text-muted">
                  ข้อมูลส่วนบุคคลและ/หรือข้อมูลของคุณอาจถูกถ่ายโอน จัดเก็บ
                  หรือดำเนินการภายนอกประเทศ โดยส่วนใหญ่
                  ข้อมูลส่วนบุคคลของคุณจะถูกนำไปดำเนินการประมวลผลในประเทศสิงคโปร์
                  ซึ่งเป็นที่ตั้งของเซิร์ฟเวอร์และฐานข้อมูลส่วนกลางของเรา DE
                  LAMUN
                  จะถ่ายโอนข้อมูลของคุณไปยังต่างประเทศตามกฎหมายเกี่ยวกับการคุ้มครองข้อมูลส่วนบุคคลเท่านั้น
                </p>
                <h5>
                  17. วิธีที่คุณสามารถยกเลิก ลบ ร้องขอการเข้าถึง
                  หรือแก้ไขข้อมูลที่คุณให้แก่เรา
                </h5>
                <p className="text-muted">17.1 การยกเลิกและถอนความยินยอม</p>
                <p className="text-muted">
                  17.1.1 หากคุณต้องการเปลี่ยนแปลงแก้ไขการสมัครใช้งานทางอีเมล
                  โปรดแจ้งให้เราทราบโดยการส่งอีเมลไปที่
                  เจ้าหน้าที่คุ้มครองข้อมูลส่วนบุคคลของเรา
                  ตามที่อยู่ที่แสดงไว้ด้านล่าง
                  โปรดจำไว้ว่าเนื่องจากเรามีกำหนดการในการจัดทำอีเมล
                  คุณอาจยังได้รับอีเมลที่อยู่ในระหว่างการจัดทำแล้ว
                </p>
                <p className="text-muted">
                  17.1.2 คุณอาจถอนความยินยอมของคุณเกี่ยวกับการเก็บรวบรวม ใช้
                  และ/หรือเปิดเผยข้อมูลส่วนบุคคลของคุณซึ่งอยู่ในครอบครองหรือควบคุมของเราได้
                  โดยการส่งอีเมลไปที่ เจ้าหน้าที่คุ้มครองข้อมูลส่วนบุคคลของเรา
                  ตามที่อยู่อีเมลที่แสดงไว้ด้านล่างในส่วน 18.2
                </p>
                <p className="text-muted">
                  17.1.3
                  เมื่อเราได้รับคำสั่งเกี่ยวกับการถอนเป็นลายลักษณ์อักษรอย่างชัดเจนจากคุณและตรวจสอบข้อมูลประจำตัวของคุณแล้ว
                  เราจะดำเนินการตามคำขอในการถอนความยินยอม
                  และหลังจากนั้นเราจะไม่เก็บรวบรวม ใช้
                  และ/หรือเปิดเผยข้อมูลส่วนบุคคลของคุณในลักษณะตามที่ระบุไว้ในการร้องขอของคุณ
                  หากเราไม่สามารถตรวจสอบข้อมูลประจำตัวหรือไม่เข้าใจคำสั่งของคุณ
                  เราจะติดต่อเพื่อขอคำอธิบายเกี่ยวกับการร้องขอดังกล่าว
                </p>
                <p className="text-muted">
                  17.1.4 อย่างไรก็ตาม
                  การถอนความยินยอมอาจทำให้เกิดผลอันเกิดจากการถอนดังกล่าวได้
                  ในเรื่องดังกล่าวนี้
                  ขึ้นอยู่กับขอบเขตของการถอนความยินยอมที่จะให้เราดำเนินการกับข้อมูลส่วนบุคคลของคุณ
                  และนั่นอาจหมายความว่าเราจะไม่สามารถให้บริการแก่คุณต่อไปได้
                  เราจึงอาจต้องยุติความสัมพันธ์ที่มีอยู่และ/หรือสัญญาที่คุณมีกับเรา
                  ฯลฯ แล้วแต่ว่าจะเป็นกรณีใด ซึ่งเราจะแจ้งให้คุณทราบ
                </p>
                <p className="text-muted">
                  17.2 การร้องขอการเข้าถึงและ/หรือการแก้ไขข้อมูลส่วนบุคคล
                </p>
                <p className="text-muted">
                  17.2.1 หากคุณมีบัญชีกับเรา
                  คุณอาจเข้าถึงและ/หรือแก้ไขข้อมูลส่วนบุคคลของคุณซึ่งอยู่ในครอบครองหรือควบคุมของเราได้ด้วยตนเองผ่านหน้าการตั้งค่าบัญชีที่อยู่ในไซต์
                  หากคุณไม่มีบัญชีกับเรา เราจะไม่มีข้อมูลส่วนบุคคลของคุณ
                </p>
                <p className="text-muted">
                  17.2.2 สำหรับการร้องขอเพื่อเข้าถึงข้อมูลส่วนบุคคล
                  เมื่อเรามีข้อมูลของคุณอย่างเพียงพอที่จะดำเนินการแล้ว
                  เราจะให้ข้อมูลส่วนบุคคลที่เกี่ยวข้องแก่คุณภายใน 30 วัน
                  (หรือหากคุณพำนักอยู่ในประเทศมาเลเซียจะใช้เวลา 21 วัน)
                  หากเราไม่สามารถตอบสนองการร้องขอของคุณภายใน 30 วันดังกล่าว
                  (หรือหากคุณพำนักอยู่ในประเทศมาเลเซียจะใช้เวลา 21 วัน)
                  เราจะแจ้งให้คุณทราบเกี่ยวกับระยะเวลาที่เร็วที่สุดที่เราสามารถให้ข้อมูลที่คุณร้องขอได้
                  โปรดจำไว้ว่ากฎหมายเกี่ยวกับการคุ้มครองข้อมูลส่วนบุคคลอาจยกเว้นข้อมูลส่วนบุคคลบางประเภทจากการร้องขอการเข้าใช้งานของคุณ
                </p>
                <p className="text-muted">
                  17.2.3 สำหรับการร้องขอเพื่อแก้ไขข้อมูลส่วนบุคคล
                  เมื่อเรามีข้อมูลของคุณเพียงพอที่จะดำเนินการแล้ว เราจะ:
                </p>
                <p className="text-muted">
                  (a) แก้ไขข้อมูลส่วนบุคคลของคุณภายใน 30 วัน
                  (หรือหากคุณพำนักอยู่ในประเทศมาเลเซียจะใช้เวลา 21 วัน)
                  หากเราไม่สามารถแก้ไขได้ภายในระยะเวลาดังกล่าว
                  เราจะแจ้งให้คุณทราบเกี่ยวกับระยะเวลาการดำเนินการแก้ไขที่เร็วที่สุด
                  โปรดจำไว้ว่ากฎหมายเกี่ยวกับการคุ้มครองข้อมูลส่วนบุคคลอาจยกเว้นข้อมูลส่วนบุคคลบางประเภทจากการร้องขอเพื่อให้แก้ไข
                  รวมถึงระบุเวลาที่จำเป็นต้องได้รับการแก้ไขจากเราในสถานการณ์
                  (ต่างๆ) แม้ว่าจะคุณจะร้องขอมาก็ตาม และ
                </p>
                <p className="text-muted">
                  (b)
                  เราจะส่งข้อมูลส่วนบุคคลที่ถูกแก้ไขไปยังทุกองค์กรซึ่งเราได้เปิดเผยข้อมูลส่วนบุคคลให้ทราบภายในระยะเวลาหนึ่งปีก่อนวันที่ทำการแก้ไข
                  เว้นแต่องค์กรนั้นไม่ต้องการข้อมูลส่วนบุคคลที่แก้ไขแล้วเพื่อวัตถุประสงค์ทางกฎหมายหรือธุรกิจ
                </p>
                <p className="text-muted">
                  17.2.4 โดยไม่ต้องพิจารณาถึงย่อหน้าย่อย (b) ข้างต้น
                  หากคุณร้องขอ
                  เราอาจส่งข้อมูลส่วนบุคคลที่แก้ไขไปให้แก่องค์หนึ่งองค์กรใดโดยเฉพาะซึ่งเราได้เปิดเผยข้อมูลส่วนบุคคลให้ทราบ
                  ภายในระยะเวลาหนึ่งปีก่อนวันที่ทำการแก้ไข
                </p>
                <p className="text-muted">
                  17.2.5
                  ภายใต้ขอบเขตที่อนุญาตโดยกฎหมายเราอาจคิดค่าธรรมเนียมตามสมควรสำหรับการดำเนินการและจัดการคำร้องของคุณในการเข้าถึงข้อมูลส่วนบุคคล
                  หากเราเลือกที่จะคิดค่าธรรมเนียม
                  เราจะแจ้งค่าธรรมเนียมโดยประมาณเป็นลายลักษณ์อักษรให้แก่คุณ
                  โปรดจำไว้ว่าเราไม่จำเป็นต้องตอบสนองหรือดำเนินการใดๆ
                  ต่อคำร้องขอการเข้าถึงข้อมูลของคุณเว้นแต่คุณตกลงที่จะจ่ายค่าธรรมเนียม
                </p>
                <p className="text-muted">
                  17.2.6
                  เราสงวนสิทธิ์ที่จะปฏิเสธการแก้ไขข้อมูลส่วนบุคคลของคุณตามข้อกำหนดที่ระบุไว้ในกฎหมายเกี่ยวกับการคุ้มครองข้อมูลส่วนบุคคล
                  ซึ่งข้อกำหนดระบุและ/หรือให้สิทธิองค์กรที่จะปฏิเสธการแก้ไขข้อมูลส่วนบุคคลในสถานการณ์ที่ระบุไว้
                </p>
                <h5>
                  18. มีคำถาม ข้อกังวล หรือคำร้องเรียนใช่หรือไม่ โปรดติดต่อเรา
                </h5>
                <p className="text-muted">
                  18.1
                  หากคุณมีคำถามหรือข้อกังวลเกี่ยวกับข้อปฏิบัติว่าด้วยความเป็นส่วนตัวของเรา
                  หรือเกี่ยวกับการจัดการบริการ โปรดติดต่อเราทันทีที่:
                  cs@delamun.com
                </p>
                <p className="text-muted">
                  18.2 หากคุณมีคำร้องเรียนหรือ ข้อข้องใจ
                  เกี่ยวกับวิธีที่เราจัดการข้อมูลส่วนบุคคลของคุณหรือเกี่ยวกับวิธีที่เรา
                  ปฏิบัติตาม กฎหมายเกี่ยวกับการคุ้มครองข้อมูลส่วนบุคคล
                  เราขอให้คุณติดต่อมาที่เราเพื่อแจ้งคำร้องเรียนหรือข้อข้องใจของคุณ
                  โปรดติดต่อเราผ่านทาง อีเมล
                  เพื่อแจ้งคำร้องเรียนหรือข้อข้องใจได้ที่: อีเมล:
                  abuse@delamun.com และส่งถึง
                  “เจ้าหน้าที่คุ้มครองข้อมูลส่วนบุคคล (Personal Data Protection
                  Officer)” โปรดส่งหนังสือบอกกล่าว/หนังสือร้องเรียนทั้งหมดไปที่
                  legal@delmaun.com และส่งถึง “ที่ปรึกษากฎหมาย (General
                  Counsel)”
                </p>
                <p className="text-muted">
                  18.3 ในกรณีที่คุณใช้อีเมลหรือจดหมายเพื่อส่งคำร้องเรียนของคุณ
                  โปรดระบุที่ส่วนหัวเรื่องว่าเป็นคำร้องเรียนเกี่ยวกับกฎหมายคุ้มครองข้อมูลส่วนบุคคล
                  ซึ่งจะช่วยให้เราดำเนินการคำร้องเรียนของคุณโดยเร่งด่วนได้โดยส่งต่อไปยังเจ้าหน้าที่ที่เกี่ยวข้องในองค์กรของเราเพื่อดำเนินการต่อไป
                  ตัวอย่างเช่น คุณอาจแทรกข้อความในส่วนหัวเรื่องว่า
                  “คำร้องเรียนเกี่ยวกับความเป็นส่วนตัว” แน่นอนว่าเราจะพยายาม
                  ดำเนินการ กับคำร้องเรียนหรือข้อข้องใจใดๆ
                  ที่คุณประสบอยู่อย่างเป็นธรรมและโดยเร็วที่สุดเท่าที่จะทำได้
                </p>
                <h5>19. ข้อกำหนดและเงื่อนไข</h5>
                <p className="text-muted">
                  โปรดอ่านเงื่อนไขการให้การบริการที่ระบุถึงการใช้งาน
                  การปฏิเสธความรับผิดชอบ
                  และข้อจำกัดการรับผิดที่ควบคุมการใช้งานไซต์และบริการ
                  รวมถึงนโยบายที่เกี่ยวข้องอื่นๆ
                </p>
              </Col>
            </Row>
          </div>
        </section>
      </React.Fragment>
    );
  }
}

export default PrivacyPolicy;

import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Row, Col } from "reactstrap";
import DocumentMeta from "react-document-meta";

class TermsOfUse extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    document.body.classList = "";
    window.addEventListener("scroll", this.scrollNavigation, true);
    window.scrollTo(0, 0);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.scrollNavigation);
  }

  render() {
    const meta = {
      title: "เงื่อนไขการซื้อสินค้าและบริการ",
      meta: {
        charset: "utf-8",
      },
    };

    return (
      <React.Fragment>
        <DocumentMeta {...meta} />
        <section className="section">
          <div className="container">
            <Row>
              <Col>
                <h6>
                  <Link to="/" className="text-dark">
                    <i className="mdi mdi-arrow-left icons"></i> กลับไปหน้าแรก
                  </Link>
                </h6>
                <h3 className="mb-4">เงื่อนไขการซื้อสินค้าและบริการ</h3>
                <h5>1. การซื้อสินค้า</h5>
                <p className="text-muted">
                  การซื้อสินค้าของคุณภายใน DE LAMUN แพลตฟอร์มที่ดำเนินการโดย DE
                  LAMUN COMPANY LIMITED และบริษัทในกลุ่มและบริษัทในเครือ
                  (เรียกแยกและเรียกรวมกันว่า “DE LAMUN”, “DELAMUN”, “เดอละมุน”,
                  “เดอ ละมุน”, “เรา”, “พวกเรา” หรือ “ของเรา”) และ “ผู้ใช้”
                  หมายถึง บุคคลธรรมดา บริษัท หรือนิติบุคคล
                  ที่ได้ยืนยันสั่งซื้อของกับเรา
                  หรือมีบัญชีซึ่งได้ลงทะเบียนกับเรา หรือใช้งานไซต์
                  (เรียกแยกและเรียกรวมกันว่า “ผู้ใช้” “คุณ” หรือ “ของคุณ”) และ
                  “สินค้าชิ้นเดียว” หรือ “สินค้าหลายชิ้น” ให้หมายความถึง
                  สินค้าใดๆ หรือสินค้าทั้งหมด สำหรับการขายทางเว็บไซต์
                </p>
                <p className="text-muted">
                  คุณอยู่ภายใต้บังคับของข้อตกลงและเงื่อนไขนี้
                  การที่คุณทำการซื้อสินค้าจากเว็บไซต์นี้
                  ถือว่าคุณได้ยอมผูกพันและตกลงยินยอมตามข้อตกลงและเงื่อนไขดังต่อไปนี้
                </p>
                <p className="text-muted">
                  <ul>
                    <li>
                      ให้ถือว่าเป็นความรับผิดชอบของท่านในการเก็บรักษา
                      “ชื่อผู้ใช้งาน” และ “รหัสผ่าน” ที่ท่านได้รับให้
                      เป็นความลับ
                    </li>
                    <li>
                      ข้อมูลทั้งหมดที่แสดงไว้ในเว็บไซต์
                      มิใช่คำเสนอของเราสำหรับการขายหรือการจัดหาซึ่งสินค้าหรือ
                      บริการใดๆ หากแต่เป็นคำเชื้อเชิญให้ท่านทำคำเสนอ ทั้งนี้
                      การส่งคำสั่งซื้อผ่านทางเว็บไซต์นี้ถือว่าท่าน
                      ได้ทำคำเสนอมาถึงเราเพื่อซื้อสินค้าที่ท่านได้เลือกในเว็บไซต์ตามข้อตกลงและเงื่อนไขฉบับนี้
                    </li>
                    <li>
                      เราจะใช้ความพยายามตามสมควรเพื่อดำเนินการให้แน่ใจว่าราคาและรายละเอียดอื่นของสินค้าที่แสดงในเว็บไซต์มีความถูกต้อง
                      อย่างไรก็ตามเราสงวนสิทธิ์ที่จะเปลี่ยนแปลงราคาและรายละเอียดอื่นของสินค้าดังกล่าวโดยไม่จำเป็นต้องแจ้งล่วงหน้าโดยเราไม่ต้องรับผิดชอบหรือมีความรับผิดไม่ว่าในทางใดสำหรับความผิดพลาดของราคาสินค้าที่แสดงไว้ในเว็บไซต์
                      และเราสงวนสิทธิ์ที่จะปฏิเสธคำสั่งซื้อที่อ้างอิงจากความผิดพลาดของราคาดังกล่าวราคาที่แสดงไว้ในเว็บไซต์นี้ใช้สำหรับการซื้อผ่านทางเว็บไซต์เท่านั้น
                      และราคาสินค้าอาจจะแตกต่างจากสินค้าชนิดเดียวกันที่ซื้อผ่านทางช่องทางขายอื่นนอกจากนั้นราคาที่แสดงไว้ในเว็บไซต์นี้ใช้บังคับและมีผลสมบูรณ์สำหรับการจัดส่งสินค้าภายในประเทศไทยเท่านั้น
                    </li>
                    <li>
                      รูปถ่ายและรูปภาพที่ปรากฏบนเว็บไซต์
                      จัดทำขึ้นเพื่อช่วยบ่งบอกถึงลักษณะของสินค้า
                      และอาจไม่เหมือนกับสินค้าของจริง
                    </li>
                    <li>
                      การได้รับคำสั่งซื้อจากท่านจะถือว่าท่านได้ตกลงยอมรับใน
                      ข้อตกลงของนโยบายข้อมูลส่วนบุคคล และ เงื่อนไขการใช้เว็บไซต์
                      ของเรา และท่านอนุญาตให้เราเก็บข้อมูล ประมวลผล เปิดเผย
                      และใช้ข้อมูลที่รวบรวมจากท่าน
                      เพื่อวัตถุประสงค์ในการดำเนินการต่อคำสั่งซื้อของท่าน
                      การจัดส่งสินค้า
                      และเพื่อวัตถุประสงค์ตามที่ระบุในข้อมูลส่วนบุคคลศึกษารายละเอียดเพิ่มเติมได้ที่{" "}
                      <Link to="/privacy-policy">นโยบายความเป็นส่วนตัว</Link>
                    </li>
                    <li>
                      การสั่งซื้อสินค้าผ่านบัตรเครดิต
                      สำหรับสินค้าที่สั่งซื้อผ่านบัตรเครดิต
                      คำสั่งซื้อของท่านจะถือว่าได้รับการสนองรับจากทางเราก็ต่อเมื่อบริษัทได้รับชำระเงินครบถ้วนและสมบูรณ์สำหรับสินค้าที่สั่งซื้อ
                      และท่านได้รับจดหมายอิเล็คโทรนิคหรือ SMS/MMS
                      จากบริษัทเราระบุรายละเอียดเกี่ยวกับคำสั่งซื้อของท่าน
                      (“การยืนยันการชำระเงิน”)
                    </li>
                    <li>
                      การสั่งซื้อสินค้าผ่านธนาคารอิเล็กทรอนิกส์
                      สำหรับสินค้าที่สั่งซื้อผ่านธนาคารอิเล็กทรอนิกส์
                      คำสั่งซื้อของท่านจะถือว่าได้รับการสนองรับจากทางเราก็ต่อเมื่อบริษัทได้รับชำระเงินครบถ้วนและสมบูรณ์สำหรับสินค้าที่สั่งซื้อ
                      และท่านได้รับจดหมายอิเล็คโทรนิคหรือ SMS/MMS
                      จากบริษัทเราและบริษัทที่ดำเนินการชำระเงินซึ่งเป็นบุคคลภายนอกสัญญา
                      ระบุรายละเอียดเกี่ยวกับคำสั่งซื้อของท่าน
                      และยืนยันว่าได้รับการชำระเงินตามจำนวนที่เรียกเก็บเรียบร้อยแล้ว
                      (“การยืนยันการชำระเงิน”)
                    </li>
                    <li>
                      การสั่งซื้อสินค้าผ่านการโอนเงินเข้าบัญชีธนาคาร
                      สำหรับสินค้าที่สั่งซื้อสินค้าผ่านการโอนเงินเข้าบัญชีธนาคาร
                      คำสั่งซื้อของท่านจะถือว่าได้รับการสนองรับจากทางเราก็ต่อเมื่อบริษัทได้รับชำระเงินครบถ้วนและสมบูรณ์สำหรับสินค้าที่สั่งซื้อ
                      และท่านได้รับจดหมายอิเล็คโทรนิคหรือ SMS/MMS
                      จากบริษัทเราและบริษัทที่ดำเนินการชำระเงินซึ่งเป็นบุคคลภายนอกสัญญา
                      ระบุรายละเอียดเกี่ยวกับคำสั่งซื้อของท่าน
                      และยืนยันว่าได้รับการชำระเงินตามจำนวนที่เรียกเก็บเรียบร้อยแล้ว
                      (“การยืนยันการชำระเงิน”)
                    </li>
                    <li>
                      การสั่งซื้อสินค้าแบบเก็บเงินปลายทาง
                      สำหรับสินค้าที่สั่งซื้อแบบเก็บเงินปลายทาง
                      คำสั่งซื้อของท่านจะถือว่าได้รับการสนองรับจากทางเราเป็นจดหมายก็ต่อเมื่อบริษัทได้รับคำสั่งการสั่งซื้อสินค้าแบบเก็บเงินปลายทางครบถ้วนและสมบูรณ์เรียบร้อยแล้ว
                      ระบุรายละเอียดเกี่ยวกับคำสั่งซื้อของท่าน
                      และยืนยันว่าได้รับการสั่งสินค้าตามจำนวนที่สั่งเรียบร้อยแล้ว
                      (“การยืนยันการสั่งซื้อสินค้า”)
                      ผู้สังซื้อสินค้าแบบเก็บเงินปลายทางห้ามปฏิเสธการรับสินค้าที่ได้ทำการสั่งไว้
                      เนื่องจากบริษัทจัดจำทน่าย และบริษัทขนส่ง เกิดความเสียหาย
                      ผู้สั่งสินค้ามีหน้าที่รับและชำระเงิน
                      หากไม่ชำระหรือปฏิเสธจะมีความผิดตาม ป.พ.พ.มาตรา 168,458
                      ซึ่งทางบริษัทมีสิทธิดำเนินคดีตามกฎหมายกำหนด
                    </li>
                  </ul>
                  ตัวบทกฎหมายอ้างอิง :
                  ประมวลกฎหมายแพ่งและพาณิชย์ว่าด้วยการเก็บเงินปลายทาง
                  <ul>
                    <li>
                      มาตรา 168
                      การแสดงเจตนาที่กระทำต่อบุคคลซึ่งอยู่เฉพาะหน้าให้ถือว่ามีผลนับแต่ผู้รับการแสดงเจตนาได้ทราบการแสดงเจตนานั้น
                      ความข้อนี้ให้ใช้ตลอดถึงการที่บุคคลหนึ่งแสดงเจตนาไปยังบุคคลอีกคนหนึ่งโดยทางโทรศัพท์
                      หรือโดยเครื่องมือสื่อสารอย่างอื่น
                      หรือโดยวิธีอื่นซึ่งสามารถติดต่อถึงกันได้ทำนองเดียวกัน
                    </li>
                    <li>
                      มาตรา 458 กรรมสิทธิ์ในทรัพย์สินที่ขายนั้น
                      ย่อมโอนไปยังผู้ซื้อตั้งแต่ขณะเมื่อได้ทำสัญญาซื้อขายกัน
                      และท่านได้รับจดหมายอิเล็คโทรนิคจากบริษัทเราและบริษัทที่ดำเนินการชำระเงินซึ่งเป็นบุคคลภายนอกสัญญา
                    </li>
                  </ul>
                </p>
                <h5>2. การชำระเงิน</h5>
                <p className="text-muted">
                  (ก) การชำระเงินผ่านทางบัตรเครดิต –
                  ท่านจะได้รับการส่งต่อไปยังเว็บภายนอกของบริษัทบัตรเครดิตซึ่งเป็นบุคคลภายนอกสัญญา
                  เพื่อดำเนินการชำระเงินของท่าน
                  โดยการชำระเงินของท่านผ่านเว็บไซต์ดังกล่าวเป็นการตกลงผูกพันท่านตามเงื่อนไขและข้อตกลงของบริษัทบัตรเครดิต
                  และข้อตกลงและเงื่อนไขของเว็บไซต์ภายนอกนั้น
                </p>
                <p className="text-muted">
                  (ข) การชำระเงินผ่านทางธนาคารอิเล็กทรอนิกส์ –
                  ท่านจะได้รับการส่งต่อไปยังเว็บภายนอกของบริษัทที่ดำเนินการชำระเงินซึ่งเป็นบุคคลภายนอกสัญญา
                  เพื่อดำเนินการชำระเงินของท่าน
                  โดยการชำระเงินของท่านผ่านเว็บไซต์ดังกล่าวเป็นการตกลงผูกพันท่านตามเงื่อนไขและข้อตกลงของบริษัทที่ดำเนินการชำระเงิน
                  และข้อตกลงและเงื่อนไขของเว็บไซต์ภายนอกนั้น
                </p>
                <p className="text-muted">
                  (ค) การสั่งซื้อสินค้าผ่านการโอนเงินเข้าบัญชีธนาคาร –
                  ท่านจะได้รับการส่งต่อไปยังเว็บภายนอกของบริษัทซึ่งเป็นบุคคลภายนอกสัญญา
                  เพื่อดำเนินการชำระเงินของท่าน
                  โดยการชำระเงินของท่านผ่านเว็บไซต์ดังกล่าวเป็นการตกลงผูกพันท่านตามเงื่อนไขและข้อตกลงของบริษัท
                  และข้อตกลงและเงื่อนไขของเว็บไซต์ภายนอกนั้น
                </p>
                <p className="text-muted">
                  (ง)
                  ได้รับการส่งต่อไปยังเว็บภายนอกของบริษัทที่ดำเนินการชำระเงินซึ่งเป็นบุคคลภายนอกสัญญา
                  เพื่อดำเนินการชำระเงินของท่าน
                  โดยการชำระเงินของท่านผ่านเว็บไซต์ดังกล่าวเป็นการตกลงผูกพันท่านตามเงื่อนไขและข้อตกลงของบริษัทที่ดำเนินการชำระเงิน
                  และข้อตกลงและเงื่อนไขของเว็บไซต์ภายนอกนั้น
                </p>
                <p className="text-muted">
                  การชำระเงินทั้งหมดจะขึ้นอยู่กับการอนุมัติของบริษัทที่ดำเนินการชำระเงินซึ่งเป็นบุคคลภายนอกสัญญา
                  และ/หรือ สถาบันการเงิน และเราจะไม่รับผิดชอบใด ๆ
                  หากบริษัทที่ดำเนินการชำระเงินซึ่งเป็นบุคคลภายนอกสัญญา และ/หรือ
                  สถาบันการเงิน ปฏิเสธการยอมรับ หรือความน่าเชื่อถือของบัตรเครดิต
                  หรือธุรกรรมการโอนเงินผ่านธนาคาร
                  หรือธุรกรรมทางธนาคารอิเล็กทรอนิกส์ หรือบริการเก็บเงินปลายทาง
                  ไม่ว่าด้วยสาเหตุใด ๆ ก็ตาม เราขอสงวนสิทธิ์ที่จะ (1)
                  ใช้ดุลยพินิจอย่างเต็มที่ในการปฏิเสธการชำระเงินใด ๆ
                  โดยไม่จำต้องแจ้งสาเหตุสำหรับการปฏิเสธนั้น และ (2)
                  เปลี่ยนแปลงเงื่อนไขการชำระเงินสำหรับสินค้าได้ตลอดเวลา
                  คำสั่งซื้อทั้งหมดที่ได้รับผ่านทางเว็บไซต์จะขึ้นอยู่กับการสนองรับคำสั่งซื้อของเรา
                  เราอาจจะยอมรับหรืออาจจะไม่ยอมรับคำสั่งซื้อของท่านตามดุลยพินิจของเรา
                </p>
                <p className="text-muted">
                  กรรมสิทธิ์ในสินค้าและความเสี่ยงในความสูญหายหรือเสียหายของสินค้าที่ท่านซื้อ
                  จะโอนกลับไปยังท่านในทันทีที่สินค้าได้ถูกนำออกไปจากอาคารสถานที่ของเรา
                  ทั้งนี้ให้อยู่ภายใต้เงื่อนไขที่ว่าเราได้รับชำระเงินครบถ้วนและสมบูรณ์สำหรับสินค้าที่สั่งซื้อแล้วเสมอ
                </p>
                <h5>3. การจัดส่งสินค้า</h5>
                <p className="text-muted">
                  (1)
                  ภายหลังจากการยืนยันการชำระเงินหรือคำสั่งซื้อสินค้าแบบเงินเก็บปลายทางเราจะดำเนินการจัดส่งสินค้าไปยังที่อยู่ซึ่งเราได้รับแจ้งไว้
                </p>
                <p className="text-muted">
                  (2)
                  การจัดส่งสินค้าจะดำเนินการสำหรับสถานที่ซึ่งตั้งอยู่ในประเทศไทยเท่านั้น
                  อย่างไรก็ตามเราขอสงวนสิทธิ์ที่จะปฏิเสธไม่จัดส่งสินค้าไปยังสถานที่ใดๆก็ได้โดยไม่จำเป็นต้องแสดงเหตุผลสำหรับการปฏิเสธนั้น
                </p>
                <p className="text-muted">
                  (3) เว้นแต่ฝ่ายเราจะได้ตกลงไว้เป็นประการอื่น
                  เราจะจัดส่งสินค้าโดยบริการส่งสินค้าผ่านทางรถขนส่งเอกชนตามที่อยู่ที่ได้แสดงไว้ในคำสั่งซื้อของท่านที่ได้ส่งผ่านทางเว็บไซต์
                </p>
                <p className="text-muted">
                  (4)
                  ถึงแม้ว่าเราจะได้ใช้ความอุตสาหะตามสมควรเพื่อให้สามารถจัดส่งสินค้าไปยังท่านได้ภายใน
                  3-5 วันทำการ นับจากท่านได้รับการยืนยันการชำระเงินแล้วก็ตาม
                  เราไม่ให้การรับประกันใดว่าเราจะสามารถดำเนินการจัดส่งได้ภายในกรอบเวลานี้
                  และวันที่สำหรับการจัดส่งสินค้าซึ่งเราได้แสดงไว้นั้น
                  เป็นวันที่โดยการคาดการณ์และไม่ถือว่าเป็นส่วนหนึ่งของสัญญาระหว่างท่านกับเรา
                  โดยเราไม่รับผิดสำหรับความล่าช้าใดที่เกิดขึ้นในการจัดส่งสินค้า
                  ไม่ว่าจะเกิดขึ้นจากสาเหตุใดก็ตาม
                </p>
                <p className="text-muted">
                  (5)
                  สินค้าทั้งหมดที่ถูกจัดส่งและได้รับตามที่อยู่ที่ระบุคำสั่งซื้อของท่าน
                  จะถือว่าการจัดส่งเรียบร้อยและคำสั่งสมบูรณ์
                  กรณีที่ไม่มีผู้รับสินค้าที่จัดส่งไป
                  เราอาจใช้ดุลยพินิจในการจัดส่งสินค้าใหม่
                  และท่านจะต้องเป็นผู้รับผิดชอบค่าใช้จ่ายสำหรับการจัดส่งสินค้าในคราวถัดไป
                </p>
                <p className="text-muted">
                  (6)
                  เราแนะนำให้ท่านตรวจสอบสินค้าทันทีที่สินค้าถูกจัดส่งถึงมือท่านภายใน
                  7 วันนับจากวันที่ท่านได้รับสินค้า
                  หากท่านพบว่าสินค้ามีการจัดส่งผิดพลาดหรือพบว่าสินค้าชำรุดบกพร่อง
                  ท่านสามารถแจ้งคืนสินค้าให้แก่เราได้ โดยติดต่อที่อีเมล
                  cs@delamun.com โดยสินค้านั้นจะต้องอยู่ในสภาพดี ไม่ถูกเปิด
                  และอยู่ในสภาพที่นำกลับมาขายต่อได้
                </p>
                <p className="text-muted">
                  (7)
                  ในกรณีที่สินค้าใดถูกจัดส่งให้โดยผิดพลาดหรือมีความชำรุดบกพร่องท่านอาจขอส่งคืนสินค้าให้แก่เราได้ภายใน
                  7 วัน นับจากวันที่ท่านได้รับสินค้านั้น โดยติดต่อที่อีเมล
                  cs@delamun.com
                  แสดงให้เห็นว่าสินค้านั้นจะต้องยังไม่ถูกเปิดและอยู่ในสภาพดีที่สามารถนำมาขายต่อได้
                  หากเกินกว่า 7วันดังกล่าว เราจะไม่ดำเนินการตามที่ท่านร้องขอ
                </p>
                <p className="text-muted">
                  (8)
                  ในกรณีที่สินค้าถูกส่งคืนมายังเราเพราะไม่สามารถจัดส่งได้นั้น
                  จะถือว่าคำสั่งซื้อถูกยกเลิกและเราจะคืนเงินค่าสินค้าให้แก่ท่านตามจำนวนเงินที่ได้เรียกเก็บจากท่านไป
                </p>
                <p className="text-muted">
                  ตามขอบเขตที่ได้รับอนุญาตโดยกฎหมายและระเบียบต่าง ๆ
                  ความรับผิดทั้งหมดของเราสำหรับการสั่งซื้อสินค้าของท่านผ่านทางเว็บไซต์และสำหรับกรณีอื่นใดไม่ว่าภายใต้สัญญา
                  ละเมิด หรือประการอื่น (รวมถึงแต่ไม่จำกัดเพียง ความสูญหาย
                  ความเสียหาย
                  หรือการบาดเจ็บที่เกิดขึ้นโดยตรงหรือโดยอ้อมจากสินค้าที่ไม่เป็นไปตามข้อตกลง
                  หรือการผิดข้อตกลงอื่นใดของเราตามที่ได้แสดงไว้นี้)
                  เราจะรับผิดไม่เกินไปกว่ามูลค่าราคารวมที่ได้ชำระสำหรับการซื้อสินค้า
                  เราจะไม่รับผิดชอบต่อท่านไม่ว่าความเสียหายโดยทางตรง ทางอ้อม
                  พิเศษ บังเอิญ หรือความเสียหายที่เกิดขึ้นภายใต้
                  หรือเกี่ยวข้องกับการซื้อสินค้า
                  (ไม่ว่าเป็นความรับผิดเกิดขึ้นจากความประมาทเลินเล่อ การผิดสัญญา
                  การแถลงข้อความเท็จ หรือไม่ว่าด้วยสาเหตุใดก็ตาม)
                  หรือความสูญหายใดที่เกิดขึ้นจากพนักงานของเรา ตัวแทน
                  หรือพนักงานบริการลูกค้า
                </p>
                <p className="text-muted">
                  ท่านตกลงจะคุ้มครองความรับผิดและปกป้องเราจากความสูญหาย
                  หรือความเสียหายใดที่มีต่อทรัพย์สิน
                  หรือทำให้เกิดอันตรายแก่ร่างกาย หรือชีวิตของบุคคลใด
                  ซึ่งเป็นผลมาจากความผิด กระทำการหรืองดเว้นกระทำการโดยประมาท
                  การจงใจทำให้เกิดความเสียหายของท่าน
                  หรือจากการประพฤติผิดสัญญาของท่านตามที่แสดงไว้ในข้อตกลงและเงื่อนไขฉบับนี้
                </p>
                <p className="text-muted">
                  การซื้อสินค้าของท่านจากเว็บไซต์นี้ถือเป็นความเสี่ยงของท่านแต่เพียงผู้เดียว
                </p>
              </Col>
            </Row>
          </div>
        </section>
      </React.Fragment>
    );
  }
}

export default TermsOfUse;

import React from "react";

export const offers = [
  {
    title: "รังนกเส้นแดงตุ๋นน้ำผึ้ง 500 ml",
    price: 2000,
    offerPrice: 2500,
    discount: "ลด ฿500",
    totalSale: 7565,
    image: require("./images/birdnest-offer-1.jpg"),
    sku: "BN1",
    url: "https://shope.ee/9eyf49bv00",
  },
  {
    title: "รังนกเส้นแดงตุ๋นโสม 500 ml",
    price: 2000,
    offerPrice: 2500,
    discount: "ลด ฿500",
    totalSale: 7026,
    image: require("./images/birdnest-offer-1.jpg"),
    sku: "BN2",
    url: "https://shope.ee/1fwNXXD12u",
  },
  {
    title: "รังนกเส้นแดงไม่มีน้ำตาล 500 ml",
    price: 2200,
    offerPrice: 2800,
    discount: "ลด ฿600",
    totalSale: 3089,
    image: require("./images/birdnest-offer-1.jpg"),
    sku: "BN3",
    url: "https://shope.ee/1fwNXXD12u",
  },
  {
    title: "รังนกเส้นขาวตุ๋นน้ำผึ้ง 500 ml",
    price: 2000,
    offerPrice: 2500,
    discount: "ลด ฿500",
    totalSale: 6572,
    image: require("./images/birdnest-offer-2.jpg"),
    sku: "BN4",
    url: "https://shope.ee/3VO1ixmp1P",
  },
  {
    title: "รังนกเส้นขาวตุ๋นโสม 500 ml",
    price: 2000,
    offerPrice: 2500,
    discount: "ลด ฿500",
    totalSale: 5389,
    image: require("./images/birdnest-offer-2.jpg"),
    sku: "BN5",
    url: "https://shope.ee/8A9rHZ8O4W",
  },
  {
    title: "รังนกเส้นขาวไม่มีน้ำตาล 500 ml",
    price: 2200,
    offerPrice: 2800,
    discount: "ลด ฿600",
    totalSale: 3949,
    image: require("./images/birdnest-offer-2.jpg"),
    sku: "BN6",
    url: "https://shope.ee/8UmhgCbNIt",
  },
  {
    title: "รังนกเศษแดงตุ๋นน้ำผึ้ง 500 ml",
    price: 1400,
    offerPrice: 1700,
    discount: "ลด ฿300",
    totalSale: 27487,
    image: require("./images/birdnest-offer-5.jpg"),
    sku: "BN7",
    url: "https://shope.ee/1qFnjyicf3",
  },
  {
    title: "รังนกเศษขาวตุ๋นน้ำผึ้ง 500 ml",
    price: 1300,
    offerPrice: 1700,
    discount: "ลด ฿300",
    totalSale: 25395,
    image: require("./images/birdnest-offer-3.jpg"),
    sku: "BN8",
    url: "https://shope.ee/8A9rHdxQl2",
  },
];

export const OffersContext = React.createContext(offers);

import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { BrowserRouter } from "react-router-dom";
import { initialTagManager, gtmDataLayer } from "./utils/TagManager";

const app = (
  <BrowserRouter>
    <App />
  </BrowserRouter>
);

initialTagManager();

const publicIp = require("public-ip");
(async () => {
  try {
    gtmDataLayer({ ip: await publicIp.v4() });
  } catch (err) {
    gtmDataLayer({ ip: await publicIp.v6() });
  }
})();

ReactDOM.render(app, document.getElementById("root"));
serviceWorker.unregister();

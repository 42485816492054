import React, { Component } from "react";
import Layout from "./components/layout/";
import {
  Route,
  Switch,
  BrowserRouter as Router,
  withRouter,
} from "react-router-dom";
import ScrollToTop from "./components/shared/ScrollToTop";

import "./Apps.scss";
import "./css/materialdesignicons.min.css";

import routes from "./routes";

const Root = React.lazy(() => import("./pages/Home"));

function withLayout(WrappedComponent) {
  return class extends React.Component {
    render() {
      return (
        <Layout>
          <WrappedComponent urlParams={this.props.match}></WrappedComponent>
        </Layout>
      );
    }
  };
}

class App extends Component {
  render() {
    return (
      <React.Fragment>
        <Router>
          <ScrollToTop />
          <React.Suspense fallback={<div></div>}>
            <Switch>
              {routes.map((route, idx) => (
                <Route
                  path={route.path}
                  component={withLayout(route.component)}
                  key={idx}
                />
              ))}

              <Route path="/" component={Root} />
            </Switch>
          </React.Suspense>
        </Router>
      </React.Fragment>
    );
  }
}

export default withRouter(App);

import React, { Component } from "react";
import { Link } from "react-router-dom";

class Footer extends Component {
  render() {
    return (
      <React.Fragment>
        <footer className="footer">
          <div className="container">
            <div className="row">
              <div className="col-lg-4 col-md-4 col-12 mt-4 mt-sm-0 pt-2 pt-sm-0">
                <h4 className="footer-head">จัดจำหน่ายโดย</h4>
                <p className="text-foot">
                  ร้านบูรพารังนก
                  <br />
                  ที่อยู่: 375/15 แขวงอรุณอมรินทร์ เขตบางกอกน้อย กทม 10700
                  <br />
                  โทร: 081-821-2220
                </p>
              </div>
              <div className="col-lg-4 col-md-4 col-12 mt-4 mt-sm-0 pt-2 pt-sm-0">
                <h4 className="footer-head">ศูนย์ช่วยเหลือ</h4>
                <ul className="list-unstyled footer-list mt-4">
                  <li>
                    <Link to="/return-policy" className="text-foot">
                      การคืนเงินและการคืนสินค้า
                    </Link>
                  </li>
                  <li>
                    <Link to="/terms-of-use" className="text-foot">
                      เงื่อนไขการซื้อสินค้าและบริการ
                    </Link>
                  </li>
                  <li>
                    <Link to="/privacy-policy" className="text-foot">
                      นโยบายความเป็นส่วนตัว
                    </Link>
                  </li>
                </ul>
              </div>
              <div className="col-lg-4 col-md-4 col-12 mt-4 mt-sm-0 pt-2 pt-sm-0"></div>
            </div>
          </div>
        </footer>
      </React.Fragment>
    );
  }
}

export default Footer;

import React, { Component } from "react";
import {
  Row,
  Col,
  Container,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
} from "reactstrap";
import { gtmDataLayer } from "../../utils/TagManager";

class ContactModal extends Component {
  render() {
    const clickContact = (clickId) =>
      gtmDataLayer({
        event: "contact",
        clickId,
      });

    return (
      <React.Fragment>
        <ModalHeader toggle={this.togglemodal}>
          ซื้อสินค้าผ่านแอดมิน
        </ModalHeader>
        <ModalBody>
          <p className="text-muted">กรุณาเลือกช่องทางติดต่อแอดมิน</p>
          <Row>
            <Col lg={12} className="mb-3">
              <a
                href={process.env.REACT_APP_LINE_URL}
                rel="noopener noreferrer nofollow"
                target="_blank"
                className="btn btn-dark btn-block"
                id="contact-line"
                onClick={() => clickContact("contact-line")}
              >
                <i className="mdi mdi-shield-account icons float-left"></i>{" "}
                แอดไลน์ (Add Line)
              </a>
            </Col>
            {process.env.REACT_APP_MESSENGER_URL !== "" && (
              <Col lg={12} className="mb-3">
                <a
                  href={process.env.REACT_APP_MESSENGER_URL}
                  rel="noopener noreferrer nofollow"
                  target="_blank"
                  className="btn btn-dark btn-block"
                  id="contact-facebook"
                  onClick={() => clickContact("contact-facebook")}
                >
                  <i className="mdi mdi-facebook-messenger icons float-left"></i>{" "}
                  เฟสบุ๊ค (Facebook)
                </a>
              </Col>
            )}
            <Col lg={12} className="mb-3">
              <a
                href={process.env.REACT_APP_TEL_URL}
                rel="nofollow"
                className="btn btn-dark btn-block"
                id="contact-tel"
                onClick={() => clickContact("contact-tel")}
              >
                <i className="mdi mdi-cellphone-android icons float-left"></i>{" "}
                โทร: {process.env.REACT_APP_TEL}
              </a>
            </Col>
          </Row>
        </ModalBody>
      </React.Fragment>
    );
  }
}
export default ContactModal;

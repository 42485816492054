import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Row, Col, Container, Modal } from "reactstrap";
import DocumentMeta from "react-document-meta";
import ReactPlayer from "react-player";
import AnchorLink from "react-anchor-link-smooth-scroll";

import Banner from "../components/shared/Banner";
import banner from "../images/banner.jpg";
import bannerMobile from "../images/banner-mobile.jpg";
import CtaBar from "../components/shared/CtaBar";
import Offer from "../components/shared/Offer";
import Footer from "../components/layout/Footer";
import ContactModal from "../components/shared/ContactModal";

class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpenModalChat: false,
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  componentWillUnmount() {}

  toggleOpenModalChat = () => {
    this.setState({ isOpenModalChat: !this.state.isOpenModalChat });
  };

  render() {
    const ctaTitle = "บูรพารังนก รังนกแท้ 100% จากเยาวราช";
    const products = ["BN1", "BN2", "BN3", "BN4", "BN5", "BN6", "BN7", "BN8"];

    const meta = {
      title: "บูรพารังนก รังนกแท้ 100% จากเยาวราช",
      description: "บูรพารังนก รังนกแท้ 100% ส่งทั่วประเทศ",
      meta: {
        charset: "utf-8",
        name: {
          keywords: "บูรพารังนก",
        },
      },
    };

    return (
      <React.Fragment>
        <DocumentMeta {...meta} />
        <Banner banner={banner} bannerMobile={bannerMobile} />
        <CtaBar title={ctaTitle} />
        <section className="bg-light pt-4">
          <Container>
            <Row>
              <Col lg="7" md="8" xs="12" className="mb-4">
                <div className="p-3 mb-6 border-bottom bg-white rounded">
                  <h1 className="text-center">
                    บูรพารังนก รังนกแท้ 100% จากเยาวราช
                  </h1>
                  <img
                    src={require("../images/birdnest-5.jpg")}
                    className="img-fluid rounded mb-3"
                  />
                  <p className="text-muted">
                    <span className="text-danger font-weight-bold">
                      บูรพารังนก รังนกแท้ 100% จากเยาวราช คัดเกรดคุณภาพดีเยี่ยม
                    </span>{" "}
                    ตุ๋นในน้ำผึ้งสวนจิตรลดา
                    เมื่อรับประทานจะได้กลิ่นหอมของน้ำผึ้ง รสชาติหวานกำลังดี
                    สัมผัสได้ถึงเนื้อรังนกแท้ๆ ส่งตรงจากร้านที่เยาวราช
                    รังนกคุณภาพ อร่อย บำรุงปอด ไต หัวใจ กระเพาะอาหาร
                    แก้อาการร้อนใน แก้อาการไอ เสมหะ
                    และช่วยรักษาระดับการเดินของโลหิตในร่างกาย
                    ทำให้ผิวพรรณเปล่งปลั่ง มีประโยชน์ต่อเด็ก สตรี และคนชรา
                  </p>
                  <p className="text-muted">
                    บูรพารังนกเหมาะสำหรับซื้อฝากคนพิเศษ คนที่เรารัก ญาติผู้ใหญ่
                    แขกที่นับถือ ฝากลูกค้าต่างประเทศ พร้อมส่งถึงบ้าน
                    ของขวัญสุดพิเศษต้องรังนกแท้ จากบูรพารังนก
                  </p>
                  <p className="text-muted">
                    หากท่านต้องการสั่งซื้อรังนกเป็นจำนวนมาก
                    หรือส่งออกไปต่างประเทศ เช่น จีน มาเลเซีย ไต้หวัน ฮ่องกง
                    สิงคโปร์ ติดต่อแอดมินได้เลยค่ะ เรามีบริการส่งต่างประเทศ
                    พร้อมราคาพิเศษ
                  </p>
                  <button
                    onClick={() =>
                      (window.location.href = "https://shope.ee/6AOmtEWYxt")
                    }
                    className="btn btn-danger btn-block mb-2 ml-1"
                    id="cta-order"
                  >
                    สั่งซื้อสินค้า (Shopee)
                  </button>
                </div>

                <div className="mt-4 react-player-wrapper mb-3">
                  <ReactPlayer
                    url="https://youtu.be/CXQ_RupxDtQ"
                    controls
                    className="react-player"
                    width="100%"
                    height="100%"
                  />
                </div>

                <div
                  className="p-3 mb-3 border-bottom bg-white rounded"
                  id="ingredient"
                >
                  <h3 className="text-center">รังนกคุณภาพที่บูรพาคัดสรร</h3>
                  <img
                    src={require("../images/birdnest-4.jpg")}
                    className="img-fluid rounded mb-3"
                  />
                  <img
                    src={require("../images/birdnest-2.jpg")}
                    className="img-fluid rounded mb-3"
                  />
                  <img
                    src={require("../images/birdnest-3.jpg")}
                    className="img-fluid rounded mb-3"
                  />
                  <img
                    src={require("../images/birdnest-14.jpg")}
                    className="img-fluid rounded mb-3"
                  />
                  <p className="text-muted">
                    <span className="text-danger font-weight-bold">
                      บูรพารังนก
                    </span>{" "}
                    มาจากรังของนกนางแอ่นที่ใช้เป็นที่วางไข่และเป็นที่อยู่ของลูกนกก่อนเริ่มหัดบิน
                    ซึ่งพ่อแม่นกจะสำรอกน้ำลายและของเหลวที่มีลักษณะเหนียวข้นออกมาเพื่อสร้างรัง
                    โดยจะมีส่วนของน้ำย่อยจากกระเพาะอาหารผสมออกมาด้วย
                    เมื่อกระทบกับลมทะเลที่โชยมาทำให้จับตัวแข็งเป็นรูปร่างรังนก
                    โดยนกนางแอ่นจะสร้างรัง 3 ครั้งในหนึ่งปี
                  </p>
                  <p className="text-muted">
                    โดยครั้งที่ 1 ถือเป็นรังนกที่มีคุณภาพดีที่สุด
                    เพราะร่างกายของนกสะสมอาหารบำรุงไว้มายาวนาน ก่อนที่จะ
                    สำรอกน้ำลายออกมา จึงมีสีขาว เหนียวข้น ไม่มีขนเจือปน
                    มีคุณค่าทางอาหารครบถ้วน
                  </p>
                  <p className="text-muted">
                    ส่วนครั้งที่ 2
                    นกจะรีบสร้างรังขึ้นมาใหม่หลังจากมีคนมาเก็บรังนกไป
                    แต่สภาพร่างกายของนกจะไม่สมบูรณ์เท่าครั้งแรก
                    น้ำลายที่สำรอกออกมาจึงมีความเหนียวข้นน้อยกว่าเดิม
                    และมีขนอ่อนของนกปนเปื้อนในรังนกด้วย ทำให้รังนกมีลักษณะหยาบ
                    และรังนกมีสีดำ
                  </p>
                  <p className="text-muted">
                    และครั้งที่ 3 รังนกสีแดง หรือหลายคนเรียกว่ารังนกสีเลือด
                    เพราะเข้าใจว่านกสำรอกน้ำลายออกมาเพื่อสร้างรังอย่างรุนแรงจนมีเลือดปนออกมา
                    ซึ่งความจริงแล้วสีแดงของรังนกที่เกิดขึ้นน่าจะมาจากอาหารและสภาพแร่ธาตุของถิ่นที่อยู่มากกว่า
                    ทำให้รังนกบางรัง แม้เป็นรังนกรังแรกก็มีสีแดงได้เช่นกัน
                    จึงเรียกรังนกสีแดงว่ารังนกแก่ ซึ่งรังนกครั้งที่ 3
                    นี้จะเก็บภายหลังจากนกนางแอ่นได้อพยพลูกน้อยออกจากรังไปแล้ว
                    ซึ่งจะตรงกับช่วงมรสุมไม่สามารถสร้างรังได้
                  </p>
                  <p className="text-muted">
                    ทั้งนี้ รังนกที่ดี มีคุณภาพ
                    จะอยู่แถบทะเลด้านตะวันออกเฉียงใต้ เช่น ประเทศไทย สิงคโปร์
                    อินโดนีเซีย และทะเลแถบมณฑลไห่นาน ประเทศจีน เป็นต้น
                    แต่ปัจจุบันมีการสร้างบ้านให้นกแอ่นมาทำรัง
                    แทนที่จะต้องไปหารังนกสัมปทานตามเกาะต่างๆ ซึ่งยุ่งยากกว่า
                    สามารถสร้างรายได้เป็นจำนวนมาก
                  </p>
                </div>

                <div className="react-player-wrapper mb-3">
                  <ReactPlayer
                    url="https://www.youtube.com/watch?v=uXHAjKeGnOQ"
                    controls
                    className="react-player"
                    width="100%"
                    height="100%"
                  />
                </div>

                <div
                  className="p-3 mb-3 border-bottom bg-white rounded"
                  id="review"
                >
                  <h3 className="text-center">ภาพความประทับใจ</h3>
                  <img
                    src={require("../images/birdnest-10.jpg")}
                    className="img-fluid rounded mb-3"
                  />
                  <img
                    src={require("../images/birdnest-7.jpg")}
                    className="img-fluid rounded mb-3"
                  />
                  <img
                    src={require("../images/birdnest-8.jpg")}
                    className="img-fluid rounded mb-3"
                  />
                  <img
                    src={require("../images/birdnest-9.jpg")}
                    className="img-fluid rounded mb-3"
                  />
                  <img
                    src={require("../images/birdnest-11.jpg")}
                    className="img-fluid rounded mb-3"
                  />
                  <img
                    src={require("../images/birdnest-12.jpg")}
                    className="img-fluid rounded mb-3"
                  />
                  <img
                    src={require("../images/birdnest-13.jpg")}
                    className="img-fluid rounded mb-3"
                  />
                  <img
                    src={require("../images/birdnest-1.jpg")}
                    className="img-fluid rounded mb-3"
                  />
                  <img
                    src={require("../images/birdnest-6.jpg")}
                    className="img-fluid rounded mb-3"
                  />
                  <img
                    src={require("../images/birdnest-16.jpg")}
                    className="img-fluid rounded mb-3"
                  />
                  <img
                    src={require("../images/birdnest-17.jpg")}
                    className="img-fluid rounded mb-3"
                  />
                  <img
                    src={require("../images/birdnest-18.jpg")}
                    className="img-fluid rounded mb-3"
                  />
                  <img
                    src={require("../images/birdnest-19.jpg")}
                    className="img-fluid rounded mb-3"
                  />
                  <img
                    src={require("../images/birdnest-20.jpg")}
                    className="img-fluid rounded mb-3"
                  />
                  <img
                    src={require("../images/birdnest-21.jpg")}
                    className="img-fluid rounded mb-3"
                  />
                  <img
                    src={require("../images/birdnest-22.jpg")}
                    className="img-fluid rounded mb-3"
                  />
                  <img
                    src={require("../images/birdnest-23.jpg")}
                    className="img-fluid rounded mb-3"
                  />
                  <img
                    src={require("../images/birdnest-24.jpg")}
                    className="img-fluid rounded mb-3"
                  />
                  <img
                    src={require("../images/birdnest-25.jpg")}
                    className="img-fluid rounded mb-3"
                  />
                </div>

                <div
                  className="p-3 mb-3 border-bottom bg-white rounded"
                  id="ingredient"
                >
                  <h3 className="text-center">8 สรรพคุณของรังนก</h3>

                  <p className="text-muted">
                    1. ประโยชน์ของรังนกดีต่อสุขภาพทางเดินหายใจ
                    คนจีนเชื่อว่ารังนกสามารถรักษาโรคทางเดินหายใจ
                    ช่วยบำรุงสุขภาพเด็กที่ร่างกายไม่แข็งแรงได้
                  </p>
                  <p className="text-muted">
                    2. รังนกใช้เป็นหนึ่งในส่วนผสมของเครื่องยาจีนสำหรับบำรุงปอด
                    ชื่อ รังนกเก๋ากี้ (เก๋ากี้เอี้ยงออ) โดยใช้เก๋ากี้ 10 กรัม
                    รังนก 5 กรัม น้ำตาลกรวด 50 กรัม ต้มกินครั้งละหนึ่งถ้วย
                    ทุกเช้าเย็น มีสรรพคุณช่วยบำรุงปอดได้ดี
                    เหมาะสำหรับผู้ที่ปอดอ่อนแอ หลอดลมอักเสบเรื้อรัง ไอแห้ง
                    ร่างกายอ่อนแอ หรือมีอาการร้อนขึ้นในตอนบ่าย
                    เหงื่อออกผิดปกติขณะหลับ ผู้ป่วยวัณโรคในระยะพักฟื้น
                    และยังเสริมสร้างการทำงานของตับและไต
                  </p>
                  <p className="text-muted">
                    3. สรรพคุณของรังนกช่วยสร้างภูมิคุ้มกันโรค
                    นักวิจัยที่เกี่ยวกับสมุนไพรจีนและยาที่ทำจากสัตว์ ค้นพบว่า
                    รังนก เป็นโปรตีนที่ละลายน้ำได้ เชื่อกันว่า
                    สามารถเสริมสร้างเซลล์ภายในระบบภูมิคุ้มกันโรคได้
                  </p>
                  <p className="text-muted">
                    4. รังนกมีสรรพคุณช่วยเพิ่มพลัง
                    แพทย์แผนจีนเชื่อว่ารังนกช่วยเสริมสร้างพลัง
                    กระตุ้นความอยากอาหาร ช่วยบำรุงไขกระดูก
                    รักษาอาการท้องเสียเรื้อรัง
                  </p>
                  <p className="text-muted">
                    5. รังนกมีสรรพคุณบำรุงร่างกาย
                    แพทย์แผนจีนจัดให้รังนกมีฤทธิ์กลางๆ ค่อนไปทางเย็น รสหวาน
                    เมื่อเข้าเส้นลมปราณ ปอด ไต ม้าม จะมีสรรพคุณบำรุงกำลัง
                    และช่วยขับระบายความร้อน ทำให้ภายในเกิดความชุ่มชื่น
                  </p>
                  <p className="text-muted">
                    6. ประโยชน์ของรังนกช่วยต้านไข้หวัดได้
                    นักสัตววิทยาของไทยได้ลองทำการตรวจสอบสารประกอบที่สกัดได้จากรังนกนางแอ่น
                    พบว่า มีคุณสมบัติเป็นตัวยับยั้ง Haemagglutination
                    ที่เกิดจากเชื้อไวรัสไข้หวัด ไข้หวัดใหญ่
                    ได้อย่างมีประสิทธิภาพ
                  </p>
                  <p className="text-muted">
                    7.
                    นักวิจัยพบว่ารังนกนางแอ่นช่วยให้ผู้ป่วยโรคเอดส์ที่ผ่านการรักษาโดยใช้รังสีฟื้นตัวได้เร็วขึ้น
                  </p>
                  <p className="text-muted">
                    8. ในรังนกนางแอ่นมีสาร Epidermal Growth Factor
                    ที่มีคุณค่าต่อระบบเลือด จึงช่วยบำรุงผิวพรรณให้อ่อนกว่าวัย
                    ชะลอความแก่ และทำให้อายุยืน
                  </p>
                </div>
              </Col>

              <Col lg="5" md="4" xs="12">
                <Offer products={products} />
              </Col>
            </Row>
          </Container>
        </section>
        <Modal
          isOpen={this.state.isOpenModalChat}
          toggle={this.toggleOpenModalChat}
          autoFocus={true}
          centered={true}
        >
          <ContactModal isOpenModalChat={this.state.isOpenModalChat} />
        </Modal>
        <Footer />
      </React.Fragment>
    );
  }
}

export default Home;

import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Row, Col, Modal } from "reactstrap";
import { OffersContext } from "../../context.config";
import { gtmDataLayer } from "../../utils/TagManager";
import ContactModal from "./ContactModal";

class Offer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpenModalChat: false,
    };
  }

  toggleOpenModalChat = () => {
    this.setState({ isOpenModalChat: !this.state.isOpenModalChat });
  };

  render() {
    const products = this.props.products;
    const offers = products.map((sku) =>
      this.context.find((v) => v.sku === sku)
    );

    console.log(products);

    const addToCart = (offer, clickId) => {
      let cart =
        localStorage.getItem("cart") == null
          ? {}
          : JSON.parse(localStorage.getItem("cart"));
      cart[offer.sku] = cart[offer.sku] === undefined ? 1 : cart[offer.sku] + 1;
      // cart[sku] = 1;
      localStorage.setItem("cart", JSON.stringify(cart));
      gtmDataLayer({
        event: "addToCart",
        clickId,
        offer,
        cart,
      });
    };

    return (
      <React.Fragment>
        <div className="text-center" id="offers">
          <div className="d-sm-block d-md-none">
            <h2>โปรโมชั่นพิเศษ</h2>
            <p>
              เพียงสั่งซื้อสินค้าภายในเดือนนี้รับส่วนลดสุดพิเศษไปเลย
              รีบหน่อยนะคะ มีจำนวนจำกัด!
            </p>
          </div>
          <Row className="row no-gutters">
            {offers.map((offer) => (
              <Col key={offer.sku} lg="6" className="mb-lg-2 mb-4 p-lg-2">
                <div className="blog rounded shadow overflow-hidden">
                  <div className="position-relative">
                    <span className="badge badge-danger badge-pill position-absolute">
                      {offer.discount}
                    </span>
                    <a onClick={() => (window.location.href = offer.url)}>
                      <img
                        src={offer.image}
                        className="img-fluid"
                        alt="{offer.title}"
                      />
                    </a>
                  </div>

                  <div className="content p-2">
                    <a
                      onClick={() => (window.location.href = offer.url)}
                      className="font-weight-bold text-dark"
                    >
                      {offer.title}
                    </a>
                    <br />
                    <span className="font-weight-bold text-danger">
                      ฿{offer.price.toLocaleString()}
                    </span>{" "}
                    <span className="text-muted small del">
                      <del>฿{offer.offerPrice.toLocaleString()}</del>
                    </span>
                    <br />
                    <span className="small">
                      ขายแล้ว {offer.totalSale.toLocaleString()} ชิ้น
                    </span>
                  </div>
                  <div className="m-2 text-center">
                    <button
                      onClick={() => (window.location.href = offer.url)}
                      className="btn btn-outline-danger btn-block"
                    >
                      ซื้อสินค้า
                    </button>
                  </div>
                </div>
              </Col>
            ))}
          </Row>
          <Modal
            isOpen={this.state.isOpenModalChat}
            toggle={this.toggleOpenModalChat}
            autoFocus={true}
            centered={true}
          >
            <ContactModal isOpenModalChat={this.state.isOpenModalChat} />
          </Modal>
        </div>
      </React.Fragment>
    );
  }
}

Offer.contextType = OffersContext;

export default Offer;

import React, { Component } from "react";

class Banner extends Component {
  render() {
    return (
      <React.Fragment>
        <section>
          <img
            src={this.props.banner}
            className="img-fluid d-none d-sm-block"
            alt="banner"
          />
          <img
            src={this.props.bannerMobile}
            className="img-fluid d-sm-none"
            alt="banner"
          />
        </section>
      </React.Fragment>
    );
  }
}
export default Banner;

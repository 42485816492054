import React, { Component } from "react";
import { withRouter } from "react-router-dom";

class Layout extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    document.getElementById("pageLoader").style.display = "block";
    setTimeout(function () {
      document.getElementById("pageLoader").style.display = "none";
    }, 0);
  }

  render() {
    return <React.Fragment>{this.props.children}</React.Fragment>;
  }
}

export default withRouter(Layout);

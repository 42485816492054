import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Row, Col } from "reactstrap";
import DocumentMeta from "react-document-meta";

class ReturnPolicy extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    document.body.classList = "";
    window.addEventListener("scroll", this.scrollNavigation, true);
    window.scrollTo(0, 0);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.scrollNavigation);
  }

  render() {
    const meta = {
      title: "การคืนเงินและการคืนสินค้า",
      meta: {
        charset: "utf-8",
      },
    };

    return (
      <React.Fragment>
        <DocumentMeta {...meta} />
        <section className="section">
          <div className="container">
            <Row>
              <Col>
                <h6>
                  <Link to="/" className="text-dark">
                    <i className="mdi mdi-arrow-left icons"></i> กลับไปหน้าแรก
                  </Link>
                </h6>
                <h3 className="mb-4">การคืนเงินและการคืนสินค้า</h3>
                <h5>1. นโยบายการคืนสินค้า</h5>
                <p className="text-muted">
                  ลูกค้าสามารถทำการเปลี่ยน/คืนสินค้าใดๆ ก็ตามที่ชำรุด
                  หรือยังไม่ได้เปิดใช้งานภายในระยะเวลา 7 วัน
                  นับจากวันที่ท่านได้รับสินค้า
                  (โดยถือตามเอกสารการรับสินค้าเป็นหลัก)
                  โดยสินค้าที่จะทำการส่งคืนจะต้องอยู่ในสภาพเดิมเหมือนกับตอนที่ท่านได้รับสินค้า
                  โดยทางบริษัทจะทำการเปลี่ยน/คืนสินค้า ภายใต้เงื่อนไข ดังนี้
                  <ul>
                    <li>
                      สินค้ามีสภาพชำรุด เสียหาย และไม่สามารถใช้งานได้ตามปกติ
                    </li>
                    <li>สินค้าส่งผิดรุ่น ผิดสี หรือขนาด</li>
                    <li>สินค้า หรือบรรจุภัณฑ์ของสินค้า เกิดการชำรุดเสียหาย</li>
                  </ul>
                  หมายเหตุ
                  <ul>
                    <li>สินค้าต้องไม่ผ่านการใช้งานและต้องอยู่ในสภาพที่ใหม่</li>
                    <li>
                      สินค้าจะต้องคืนมาพร้อมบรรจุภัณฑ์ของสินค้าและอุปกรณ์เสริม
                    </li>
                    <li>
                      หากท่านรับสินค้าไปเกิน 7 วัน
                      ทางบริษัทขอสงวนสิทธิ์ในการรับคืนสินค้าจากท่าน
                    </li>
                    <li>
                      กรณีสินค้าที่เป็นลดราคา (Stock Clearance)
                      ทางบริษัทขอสงวนสิทธิ์ในการคืนสินค้าหรือคืนเงินทุกกรณี
                    </li>
                  </ul>
                </p>
                <h5>2. วิธีการส่งคืนสินค้า</h5>
                <p className="text-muted">
                  หากท่านต้องการส่งคืนสินค้า
                  กรุณาดูรายละเอียดเกี่ยวกับนโยบายการคืนสินค้า
                  เพื่อตรวจสอบว่าสินค้าส่งคืนของท่านอยู่ในเงื่อนไขการส่งคืนสินค้า
                  หากท่านต้องการคืนสินค้า / เปลี่ยนสินค้าชิ้นใหม่
                  กรุณาส่งอีเมลมาที่ cs@delamun.com
                </p>
                <h5>3. ตรวจสอบสถานะการคืนสินค้า</h5>
                <p className="text-muted">
                  กระบวนการเปลี่ยนสินค้า / คืนเงิน
                  จะเริ่มดำเนินการหลังจากทางบริษัทได้ทำการประเมินสินค้าที่ส่งคืนจากท่านเป็นที่เรียบร้อยแล้ว
                  ซึ่งกระบวนการประเมินคุณภาพสินค้านี้อาจใช้เวลาประมาณ 7 วันทำการ
                  นับจากวันที่บริษัทได้รับคืนสินค้า ท่านสามารถติดต่อสอบถามได้ที่
                  cs@delamun.com
                </p>
                <h5>4. ค่าใช้จ่ายในการจัดส่งสินค้าคืน</h5>
                <p className="text-muted">
                  หากสินค้าที่ท่านได้รับไม่ถูกต้อง ชำรุด หรือเสียหาย
                  ทางบริษัทยินดีที่จะไปรับสินค้าและทำการเปลี่ยนสินค้าให้ตามที่อยู่ที่ได้ระบุไว้ในเอกสารคำสั่งซื้อ
                  โดยไม่มีค่าใช้จ่ายใดๆ
                </p>
                <h5>5. การเปลี่ยนสินค้าใหม่</h5>
                <p className="text-muted">
                  ทันทีที่ทางเราได้รับสินค้าของท่านคืน
                  ทางเราจะทำการประเมินสินค้าและตรวจสอบคุณภาพสินค้าคืนของท่าน
                  ซึ่งการดำเนินการจะใช้เวลาการตรวจสอบประมาณ 7 วันทำการ
                  นับจากวันที่บริษัทได้รับคืนสินค้า
                  หลังจากอนุมัติการเปลี่ยนสินค้าชิ้นใหม่
                  แล้วทางบริษัทจะทำการจัดส่งสินค้าชิ้นใหม่ให้แก่ท่าน
                  ตามระยะเวลาในการจัดส่งสินค้าที่กำหนดไว้
                </p>
                <h5>6. นโยบายการคืนเงิน</h5>
                <p className="text-muted">
                  ทางบริษัทจะทำการคืนเงินเต็มจำนวนของรายการสั่งซื้อให้แก่ลูกค้า
                  ในกรณีที่บริษัทไม่สามารถหาสินค้ามาเปลี่ยนหรือทดแทนสินค้าเดิมที่ท่านได้สั่งซื้อไปได้
                  ในกรณีการคืนเงิน ท่านจะต้องส่งเอกสารและหลักฐานทางบัญชี
                  เพื่อให้บริษัทดำเนินการเรื่องการจ่ายเงินคืนเข้าในบัญชีธนาคารของท่าน
                  โดยจะมีระยะเวลาในการดำเนินการประมาณ 30-45 วันทำการ
                  และทางบริษัทจะทำการแจ้งให้ท่านทราบ
                  เมื่อได้มีการจ่ายเงินคืนเป็นที่เรียบร้อยแล้ว หมายเหตุ
                  เอกสารหลักฐานในการขอคืนเงิน คือ
                  <ul>
                    <li>สำเนาบัตรประชาชน และ</li>
                    <li>
                      สำเนาหน้าบัญชีธนาคารของลูกค้า พร้อมทั้งเซ็นต์สำเนาถูกต้อง
                      (ชื่อในบัญชีธนาคารกับชื่อผู้ที่สั่งซื้อ จะต้องตรงกัน)​
                    </li>
                  </ul>
                  ทั้งนี้บริษัทขอสงวนสิทธิ์ในการเปลี่ยนแปลงเงื่อนไขใดๆ
                  โดยไม่จำเป็นต้องแจ้งให้ทราบล่วงหน้า
                </p>
              </Col>
            </Row>
          </div>
        </section>
      </React.Fragment>
    );
  }
}

export default ReturnPolicy;
